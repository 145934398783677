// user_mass_actions_controller.js
import ApplicationController from "./application_controller";
import API from '../lib/api'

export default class UsersMassActionsController extends ApplicationController {
  static targets = [
    'checkbox',
    'selectAllCheckbox'
  ]
  
  initialize(){
  }

  markAll(){
    let checkedState = this.selectAllCheckboxTarget.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checkedState;
    })
  }

  disableAll(){
    let selectedUserIds = this.checkedCheckboxes().map((checkbox) => checkbox.dataset['userId'])

    if(selectedUserIds.length == 0){
      alert('No user is selected for the specified Action')
      return;
    }
    let confirmation = confirm('Are you sure?');
    if (!confirmation)
      return;

    $.ajax({
      url: Routes.mass_deactive_users_path(),
      type: 'POST',
      data: { user_ids: selectedUserIds }
    });
  }

  enableAll(){
    let selectedUserIds = this.checkedCheckboxes().map((checkbox) => checkbox.dataset['userId'])

    if(selectedUserIds.length == 0){
      alert('No user is selected for the specified Action')
      return;
    }

    let confirmation = confirm('Are you sure?');
    if (!confirmation)
      return;

    $.ajax({
      url: Routes.mass_activate_users_path(),
      type: 'POST',
      data: { user_ids: selectedUserIds }
    });
  }

  // Not required
  assignReviewerAll(){
    let selectedUserIds = this.checkedCheckboxes().map((checkbox) => checkbox.dataset['userId'])
    if(selectedUserIds.length == 0){
      alert('No user is selected for the specified Action')
      return;
    }

    let confirmation = confirm('Are you sure?');
    if (!confirmation)
      return;

    $.ajax({
      url: Routes.assign_mass_reviewer_users_path(),
      type: 'POST',
      data: { user_ids: selectedUserIds }
    });
  }

  // Not required
  removeReviewerAll(){
    let selectedUserIds = this.checkedCheckboxes().map((checkbox) => checkbox.dataset['userId'])
    
    if(selectedUserIds.length == 0){
      alert('No user is selected for the specified Action')
      return;
    }

    let confirmation = confirm('Are you sure?');
    if (!confirmation)
      return;

    $.ajax({
      url: Routes.remove_mass_reviewer_users_path(),
      type: 'POST',
      data: { user_ids: selectedUserIds }
    });
  }

  deleteAll(){
    let selectedUserIds = this.checkedCheckboxes().map((checkbox) => checkbox.dataset['userId'])

    if(selectedUserIds.length == 0){
      alert('No user is selected for the specified Action')
      return;
    }

    let confirmation = confirm('Are you sure?');
    if (!confirmation)
      return;

    $.ajax({
      url: Routes.mass_delete_users_path(),
      type: 'DELETE',
      data: { user_ids: selectedUserIds }
    });
  }

  checkedCheckboxes(){
    return this.checkboxTargets.filter((checkbox) => checkbox.checked); 
  }

  toggleMassActionField(e){
    let referenceFieldId = e.currentTarget.dataset.referenceFieldId
    let referenceField = document.querySelector('#'+referenceFieldId)
    if (e.currentTarget.checked) {
      referenceField.disabled = false
    } else {
      referenceField.disabled = true
    }
  }

  submitBulkUpdate(e){
    e.preventDefault()
    let selectedUserIds = [];
    let selectedFields = {};
    const selectedRole = document.getElementById('userRoleDropdownSelect').value;
    let selectedMassActionCheckboxes = Array.from(document.querySelectorAll('.js-mass-action')).filter((checkbox) => {
      return checkbox.disabled === false
    })

    // selected input(checkbox) from mass action modal
    if (selectedMassActionCheckboxes.length > 0) {
      selectedMassActionCheckboxes.forEach((enabledCheckbox)=> {
        selectedFields[enabledCheckbox.name] = enabledCheckbox.checked
      })
    } else {
      alert('No Stage is selected for the specified Action')
      return
    }

    // selected users from users for bulk action
    const selectedUsers = this.checkboxTargets.filter(checkbox => checkbox.checked);
    if (selectedUsers.length > 0) {
      selectedUserIds = selectedUsers.map(input => input.dataset.userId);
    } else {
      alert('No User is selected for the specified Action')
      return
    }

    // Send AJAX request if there are enabled checkboxes and selected user IDs
    if (selectedUserIds.length > 0 && Object.keys(selectedFields).length > 0) {
      $.ajax({
        url: Routes.bulk_update_users_path(),
        type: 'PATCH',
        data: { user_ids: selectedUserIds, selected_attributes: selectedFields, role: selectedRole},
      });
    }
  }
}
