window.additionalYMarginRatio = 0.03;
window['canvasInstances'] = [];
window['cropper'] = undefined;
window['typing_form'] = undefined;
window['button_scroller'] = undefined;
window['notes_modal'] = undefined;
window['images_preloader'] = undefined;
window['preview_modal'] = undefined
window['isEnabledDisplayOnlyBookmarkedItems'] = false
window['onscreen_keyboard'] = undefined;
window['alternative_histories'] = undefined;
window['isLatinHistoryModalOpen'] = false
