// TODO: RENAME this controller, as this is used for page lists of dictionary for a particular link
import { Controller } from 'stimulus'
import Dropzone from 'dropzone'
import API from '../lib/api'

export default class extends Controller {

  static targets = ['uploadZone', 'imageContainer', 'selectAllCheckbox', 'deletePagesButton', 'dictionaryPageNumberInput']

  initialize() {
    Dropzone.autoDiscover = false
    const dropzone = new Dropzone(
      '[data-dropzone="true"]',
      {
        url: this.uploadZoneTarget.dataset.uploadUrl,
        paramName: 'image',
        withCredentials: true,
        acceptedFiles: 'image/*',
        params: {
          authenticity_token: document.querySelector('[name="csrf-token"]').content,
        }
      }
    )
  }

  // when some text is edited in input field related to page number, this method sends the page related params to mehtod in page controller
  updatePageNumber(event) {
    const input        = event.target
    const pageNumber   = input.value
    const pageId       = input.dataset.pageId
    $.ajax({
      url: '/pages/' + pageId + '/update_page_number',
      type: 'PATCH',
      data: {
        page_number: pageNumber,
      },      
      success: (response) => {
        if (response.message) {
          window.showFlashMessage(response.message, 'success');
        }
      },
      error: (xhr) => {
        if (xhr.responseJSON && xhr.responseJSON.error) {
          window.showFlashMessage(xhr.responseJSON.error, 'error');
        }
      }
    });
  }

  // Todo: Refactor mass deletion, send a mass request instead of individual requests
  deletePages() {
    if(this.selectAllCheckboxTarget.checked){
      var confirmAllDelete = confirm("Are you sure you want to delete all pages? This action might delete the dictionary as well");
      if (!confirmAllDelete) {
        return;
      }
    }

    this.imageContainerTarget.querySelectorAll('[data-bulk-action="true"]:checked').forEach(async (element, index, arrayItself) => {
      const response = await API.delete({
        url: Routes.page_path(element.value, { format: 'json' }),
        data: { },
      })
      if (document.getElementById(element.dataset.itemDivId))
        document.getElementById(element.dataset.itemDivId).remove();

      if(this.selectAllCheckboxTarget.checked && (index == arrayItself.length - 1)){
        window.location.href = '/';
      }
    })
  }

  check(e) {
    if (!e.currentTarget.checked)
      this.selectAllCheckboxTarget.checked = false
  }

  // Todo: this should be handled through a generic checkboxes and select-all controller/interface
  selectAll(e) {
    this.imageContainerTarget.querySelectorAll('[data-bulk-action="true"]').forEach((checkbox) => {
      checkbox.checked = e.currentTarget.checked
    })
  }

  keyboardShortcuts(e) {
    let eventObject = window.event ? event : e;
    if(eventObject.key == 'Backspace'){
      this.deletePagesButtonTarget.click();
    }
  }
}
