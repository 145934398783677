import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = [
    'currentItemIndexContainer']

  initialize(){
    window['preview_modal'] = this
    this.pausedChangingPreviewItem = false
    this.typing_stage = this.element.dataset['typing']
    // setting total count of items at first
    this.setTotalItemsOfPreview()
    this.displayCurrentTabIndex()
    let currentActiveTab = this.currentActiveItemOfPreviewModal()
    this.setProgressNoteColorOfActiveItem()
    // this.calculateEntriesProgressBar(currentActiveTab)
  }

  getLink(){
    let selectedItem = document.querySelector('.js-preview-tab-content.active').querySelector('.js-preview-active-item-with-link.active');//get the current selected preview item
    let url = selectedItem.getAttribute('data-item-link');//get url from data attribute
    this.copyToClipboard(url);
  }

  copyToClipboard(url) {
    navigator.clipboard.writeText(url);
  }

  hotKeys(e){
    const eventObject = window.event ? event : e
    const enter = 13
    const shift = 16
    const altKey = 18
    const alt = 18
    const esc = 27
    const pgUp = 33
    const pgDown = 34
    const left = 37
    const up = 38
    const right = 39
    const down = 40
    
    if (preview_modal == undefined){
      return true;
    }

    if (eventObject.keyCode == enter) {
      // when badge of current item index is not in edit mode
      if (this.currentItemIndexContainerTarget.contentEditable == 'inherit' || this.currentItemIndexContainerTarget.contentEditable == 'false'  ){
        let link = document.querySelector('.js-preview-active-item-with-link.active').dataset.itemLink
        window.open(link, '_blank');
      } else {
        let currentItemIndexContainer = this.currentItemIndexContainerTarget
        if ((!/[0-9]/.test(event.key) && event.keyCode !== 13) || (event.keyCode === 13 && currentItemIndexContainer.innerText == '')) {
          event.preventDefault();
        } else if (event.keyCode === 13 && /[0-9]/.test(currentItemIndexContainer.innerText)) {
          this.showSelectedPreviewItem();
        }
      }
    }else if([left, right].includes(eventObject.keyCode)) {
      // For counter mutiple events occur
      if (!this.pausedChangingPreviewItem) {
        this.pausedChangingPreviewItem = true
        this.changePreviewItem(eventObject.keyCode)

        setTimeout(() => {
          this.pausedChangingPreviewItem = false
        }, 200)
      }
    }else if(eventObject.ctrlKey && eventObject.keyCode == enter){
      let link = document.querySelector('.js-preview-active-item-with-link.active').dataset.itemLink
      if (this.typing_stage == 'type_l') {
        link = link.replace('type_o', 'type_l')
      }
      window.open(link, '_blank');
    }
  }

  //TODO: move next or previous tab of preview modal based on right or left cursor key 
  changePreviewItem(currentEventObject){
    let right = 39, left = 37
    if (currentEventObject === right || currentEventObject === left) {
      let currentActiveTab = this.currentActiveItemOfPreviewModal()
      if (currentEventObject === right) {
        if (currentActiveTab !== null && currentActiveTab.nextElementSibling !== null) {
          currentActiveTab.nextElementSibling.click()
          currentActiveTab.nextElementSibling.focus()
          this.setProgressNoteColorOfActiveItem()
          // this.calculateEntriesProgressBar(currentActiveTab.nextElementSibling)
        } else {
          // for click to next outer tab of preview modal
          let nextOuterTab = this.adjacentOuterTabPreviewModal({ outerTab: 'nextTab' })
          if (nextOuterTab) {
            nextOuterTab.click()
          }
        }
      } else {
        if (currentActiveTab !== null && currentActiveTab.previousElementSibling !== null) {
          currentActiveTab.previousElementSibling.click()
          currentActiveTab.previousElementSibling.focus()
          this.setProgressNoteColorOfActiveItem()
          // this.calculateEntriesProgressBar(currentActiveTab.previousElementSibling)
        } else {
          // for click to previous outer tab of preview modal
          let previousOuterTab = this.adjacentOuterTabPreviewModal({ outerTab: 'previousTab' })
          if (previousOuterTab) {
            previousOuterTab.click()
          }
        }
      }
    }
  }

  // setting total count of items at first
  setTotalItemsOfPreview(){
    let allTabs = document.querySelectorAll('.js-btn-preview-inner-tab')
    document.querySelector('.total_items_length').innerHTML = allTabs.length
  }

  navigatePreviewItemToEnteredIndex(e) {
    this.currentItemIndexContainerTarget.contentEditable = true;
    this.currentItemIndexContainerTarget.focus();
    this.currentItemIndexContainerTarget.innerText = '';
  }

  // To show preview item by wrote value
  showSelectedPreviewItem(){
    let currentItemIndexContainer = this.currentItemIndexContainerTarget
    let allTabs = document.querySelectorAll('.js-btn-preview-inner-tab')
    if (Number(currentItemIndexContainer.innerText) <= allTabs.length ) {
      allTabs[currentItemIndexContainer.innerText-1].click()
      currentItemIndexContainer.blur()
    } else {
      allTabs[allTabs.length - 1].click()
      currentItemIndexContainer.blur()
    }
    setTimeout(function(){
      currentItemIndexContainer.contentEditable = false
    }, 200)
  }

  setProgressNoteColorOfActiveItem(){
    // delay for fetching current active item
    setTimeout(function(){
      let currentActiveTab = preview_modal.currentActiveItemOfPreviewModal()
      if (currentActiveTab.dataset.hasNote == 'true') {
        preview_modal.itemNoteProgressBar()
      } else {
        preview_modal.itemWithoutNoteProgressBar()
      }
    }, 250)
  }

  itemNoteProgressBar() {
    document.querySelector('.js-preview-active-item-with-link.active').querySelector('.js-preview-progress-bar').classList.replace('bg-success', 'bg-danger')
    document.querySelector('.js-preview-active-item-with-link.active').querySelector('.progress').style.backgroundColor =   '#FFB6C1'
  }

  itemWithoutNoteProgressBar() {
    document.querySelector('.js-preview-active-item-with-link.active').querySelector('.js-preview-progress-bar').classList.replace('bg-danger', 'bg-success')
    document.querySelector('.js-preview-active-item-with-link.active').querySelector('.progress').style.backgroundColor =   ''
  }

  // updating current tab index
  displayCurrentTabIndex(){
    setTimeout(function(){
      let currentActiveTab = preview_modal.currentActiveItemOfPreviewModal()
      let allTabs = document.querySelectorAll('.js-btn-preview-inner-tab')
      let nextActiveTabIndex = Array.from(allTabs).indexOf(currentActiveTab)
      document.querySelector('.current-item-index').innerHTML = nextActiveTabIndex + 1
      document.querySelector('.js-preview-remaining-count').innerHTML = allTabs.length - (nextActiveTabIndex + 1)
      }
    , 250)
  }

  // calculating preview progress bar
  calculateEntriesProgressBar(currentActiveTab){
    if (this.typing_stage == 'entries' || this.typing_stage == 'type_o' || this.typing_stage == 'type_l' ) {
      let currentEntryIndex = currentActiveTab.dataset.typingNumber
      let totalEntriesSize = document.querySelectorAll('.js-btn-preview-inner-tab').length
      let progressBar = document.querySelector('.js-preview-progress-bar')
      let currentProgressPosition = (currentEntryIndex / totalEntriesSize)* 100
      progressBar.style.width =`${currentProgressPosition}%`
    }
  }

  // TODO: Make and move to Preview controller
  currentActiveItemOfPreviewModal(){
    return document.querySelector('.js-preview-tab-content.active').querySelector('.js-btn-preview-inner-tab.active')
  }

  // TODO: Return next or previous outer preview tab
  adjacentOuterTabPreviewModal({ outerTab = null }){
    const previewOutertabs = document.querySelectorAll('.js-preview-outer-tab');
    let currentTabIndex = null
    let tabIndex = null
    // TODO: we will use to findIndex instead this later
    previewOutertabs.forEach((tab, index) => {
      if (tab.classList.contains('active')) {
        currentTabIndex = index
      }
    });
    if (outerTab == 'nextTab') {
      tabIndex = 1
    } else if( outerTab == 'previousTab' ) {
      tabIndex = -1
    }

    return previewOutertabs[currentTabIndex+tabIndex]
  }
}
