import { Controller } from 'stimulus'
import { initUtils } from '../lib/canvas_utils'

export default class extends Controller {
  initialize() {
    const originalImage = this.element.dataset.originalImage
    const points = JSON.parse(this.element.dataset.points)
    const originalImageElement = new Image()
    originalImageElement.crossOrigin = 'anonymous'
    originalImageElement.src = originalImage
    originalImageElement.onload = () => {
      const tempCanvas = document.createElement('canvas')
      const tempContext = tempCanvas.getContext('2d')
      initUtils(tempContext)
      this.element.src = tempContext.getClippedDataUrl(
        points,
        originalImageElement
      )
      tempCanvas.remove()
      originalImageElement.remove()
    }
  }
}
