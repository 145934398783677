// dictionary_form_controller.js
import ApplicationController from "./application_controller";

export default class DictionaryFormController extends ApplicationController {
  static targets = [
    // NOTE:- commented targets not required currently but kept in case client revert back to previous requirements 
    // 'crop_per_set_radio',
    // 'crop_per_line_radio',
    // 'crop_total_field',
    // 'crop_per_set_fee_field',
    'crop_per_line_fee_field',

    // 'sslice_per_set_radio',
    // 'sslice_per_line_radio',
    // 'sslice_total_field',
    // 'sslice_per_set_fee_field',
    'sslice_per_line_fee_field',

    // 'highlight_per_set_radio',
    // 'highlight_per_item_radio',
    // 'highlight_total_field',
    // 'highlight_per_set_fee_field',
    'highlight_per_item_fee_field',

    // 'typeo_per_set_radio',
    // 'typeo_per_character_radio',
    // 'typeo_total_field',
    // 'typeo_generated_total_field', // target used to give default fee string
    // 'typeo_per_set_fee_field',
    // 'typeo_per_generated_set_fee_field', // target being used only to toggle display of relevant field, not affecting any values just toggling field visibility
    'typeo_per_edited_character_fee_field', // target being used only to toggle display of relevant field, not affecting any values just toggling field visibility
    'typeo_per_generated_character_fee_field', // target being used only to toggle display of relevant field, not affecting any values just toggling field visibility
    'typeo_generated_character_field_container', // target being used only to toggle display of relevant div, not affecting any values just toggling div visibility
    // 'per_set_label', // target being used only to toggle display of relevant label div, not affecting any values just toggling label visibility
    // 'per_character_label', // // target being used only to toggle display of relevant label div, not affecting any values just toggling label visibility

    // 'typel_per_set_radio',
    // 'typel_per_character_radio',
    // 'typel_total_field',
    // 'typel_per_set_fee_field',
    'typel_per_character_fee_field',

    // 'totalFee'
  ];

  initialize(){
    this.setsCount = this.element.dataset.setsCount;
    // NOTE:- commented methods not required currently but kept in case client revert back to previous requirements 
    //this.updateDictionaryFields()  
  }

  updateDictionaryFields(){
    this.#toggleFeeFeilds()
    this.#calculateAndDisplayFees()
  }

  #calculateAndDisplayFees(){
    // Calculating totals
    let noTotal = false;
    let totalCost = 0.0;

    if(this.crop_per_set_radioTarget.checked){
      let totalCropCost = Number(this.crop_per_set_fee_fieldTarget.value);
      totalCropCost = totalCropCost * this.setsCount;
      totalCost += totalCropCost
      this.crop_total_fieldTarget.value = `Total: ${totalCropCost}`
    } else {
      this.crop_total_fieldTarget.value = 'Total: --'
      noTotal = true
    }

    if(this.sslice_per_set_radioTarget.checked){
      let totalCropCost = Number(this.sslice_per_set_fee_fieldTarget.value);
      totalCropCost = totalCropCost * this.setsCount;
      totalCost += totalCropCost
      this.sslice_total_fieldTarget.value = `Total: ${totalCropCost}`
    } else {
      this.sslice_total_fieldTarget.value = 'Total: --'
      noTotal = true
    }

    if(this.highlight_per_set_radioTarget.checked){
      let totalCropCost = Number(this.highlight_per_set_fee_fieldTarget.value);
      totalCropCost = totalCropCost * this.setsCount;
      totalCost += totalCropCost
      this.highlight_total_fieldTarget.value = `Total: ${totalCropCost}`
    } else {
      this.highlight_total_fieldTarget.value = 'Total: --'
      noTotal = true
    }

    if(this.typeo_per_set_radioTarget.checked){
      let totalCropCost = Number(this.typeo_per_set_fee_fieldTarget.value);
      totalCropCost = totalCropCost * this.setsCount;
      totalCost += totalCropCost
      this.typeo_total_fieldTarget.value = `Total: ${totalCropCost}`
    } else {
      this.typeo_total_fieldTarget.value = 'Total: --'
      this.typeo_generated_total_fieldTarget.value = 'Total: --'
      noTotal = true
    }

    if(this.typel_per_set_radioTarget.checked){
      let totalCropCost = Number(this.typel_per_set_fee_fieldTarget.value);
      totalCropCost = totalCropCost * this.setsCount;
      totalCost += totalCropCost
      this.typel_total_fieldTarget.value = `Total: ${totalCropCost}`
    } else {
      this.typel_total_fieldTarget.value = 'Total: --'
      noTotal = true
    }

    if (noTotal){
      this.totalFeeTarget.value = '--'
    } else {
      this.totalFeeTarget.value = `Total: ${totalCost}`
    }
  }

  #toggleFeeFeilds(){
    // Toggle fields
    if(this.crop_per_set_radioTarget.checked){
      this.crop_per_set_fee_fieldTarget.classList.toggle('collapse', false)
      this.crop_per_line_fee_fieldTarget.classList.toggle('collapse', true)
    } else {
      this.crop_per_set_fee_fieldTarget.classList.toggle('collapse', true)
      this.crop_per_line_fee_fieldTarget.classList.toggle('collapse', false)
    }

    if(this.sslice_per_set_radioTarget.checked){
      this.sslice_per_set_fee_fieldTarget.classList.toggle('collapse', false)
      this.sslice_per_line_fee_fieldTarget.classList.toggle('collapse', true)
    } else {
      this.sslice_per_set_fee_fieldTarget.classList.toggle('collapse', true)
      this.sslice_per_line_fee_fieldTarget.classList.toggle('collapse', false)
    }

    if(this.highlight_per_set_radioTarget.checked){
      this.highlight_per_set_fee_fieldTarget.classList.toggle('collapse', false)
      this.highlight_per_item_fee_fieldTarget.classList.toggle('collapse', true)
    } else {
      this.highlight_per_set_fee_fieldTarget.classList.toggle('collapse', true)
      this.highlight_per_item_fee_fieldTarget.classList.toggle('collapse', false)
    }

    if(this.typeo_per_set_radioTarget.checked){
      this.typeo_per_set_fee_fieldTarget.classList.toggle('collapse', false)
      this.per_set_labelTarget.classList.toggle('collapse', false) 
      this.per_character_labelTarget.classList.toggle('collapse', true) 
      this.typeo_per_generated_set_fee_fieldTarget.classList.toggle('collapse', true)
      this.typeo_per_edited_character_fee_fieldTarget.classList.toggle('collapse', true) 
      this.typeo_per_generated_character_fee_fieldTarget.classList.toggle('collapse', true)  
      this.typeo_generated_character_field_containerTarget.classList.toggle('collapse', true)
    } else {
      this.per_set_labelTarget.classList.toggle('collapse', true) 
      this.per_character_labelTarget.classList.toggle('collapse', false)
      this.typeo_per_set_fee_fieldTarget.classList.toggle('collapse', true)
      this.typeo_per_generated_set_fee_fieldTarget.classList.toggle('collapse', true)
      this.typeo_per_edited_character_fee_fieldTarget.classList.toggle('collapse', false)
      this.typeo_per_generated_character_fee_fieldTarget.classList.toggle('collapse', false)
      this.typeo_generated_character_field_containerTarget.classList.toggle('collapse', false)
    }

    if(this.typel_per_set_radioTarget.checked){
      this.typel_per_set_fee_fieldTarget.classList.toggle('collapse', false)
      this.typel_per_character_fee_fieldTarget.classList.toggle('collapse', true)
    } else {
      this.typel_per_set_fee_fieldTarget.classList.toggle('collapse', true)
      this.typel_per_character_fee_fieldTarget.classList.toggle('collapse', false)
    }
  }
}
