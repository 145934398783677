//dictionary_permission_checkbox_controller.js
// this controller handles dictionary permissions checkbox selections e.g if highlight box is selected then select all previous checkboxes.
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // Select all checkboxes within the controller's scope
    const checkboxes = this.element.querySelectorAll("input[type='checkbox']");

    checkboxes.forEach((checkbox, index) => {
      checkbox.addEventListener("change", (event) => {
        // If the clicked checkbox is unchecked, uncheck subsequent checkboxes
        if (event.target.checked) {
            this.checkPreviousCheckboxes(checkboxes, index);
        }
        else {
          this.uncheckSubsequentCheckboxes(checkboxes, index);
        }
      });
    });
  }

  checkPreviousCheckboxes(checkboxes, endIndex) {
    for (let i = 0; i < endIndex; i++) {
      checkboxes[i].checked = true;
    }
  }

  uncheckSubsequentCheckboxes(checkboxes, startIndex) {
    for (let i = startIndex + 1; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  }
}
