import ApplicationController from "./application_controller"

// TODO: Rename. This controller switches tabs between reviewer and normal users 
export default class extends ApplicationController {
  static targets =[
    'memberTab',
    'reviewerTab',
  ]

  initialize(){
    // Not being used
    this.actionName  = this.element.dataset.actionName;
  }
  
  switchToReviewer(e){
    if (e.currentTarget.innerText == 'Member') {
      this.reviewerTabTarget.classList.add('hide_thumbnail')
      this.memberTabTarget.classList.remove('hide_thumbnail')
      e.currentTarget.children[1].innerText = 'Reviewer'
    } else {
      this.reviewerTabTarget.classList.remove('hide_thumbnail')
      this.memberTabTarget.classList.add('hide_thumbnail')
      e.currentTarget.children[1].innerText = 'Member'
    }
  }
}
