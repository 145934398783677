import { Controller } from 'stimulus'

// this controller for referesh all feather icon for show on screen
export default class extends Controller {
  
  refereshIcons(){
    setTimeout(function() {
      feather.replace();
    }, 100)
  }
}
