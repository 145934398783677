import { Controller } from "stimulus";

export default class extends Controller {
  openStageSectionInModal(e) {
    let data = {
      stage: e.currentTarget.dataset.stage,
      title: e.currentTarget.dataset.title,
    }

    $.ajax({
      url: '/reports/sets_report.js',
      type: 'GET',
      data: data
    });
  }
}
