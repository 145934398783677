// app/javascript/controllers/images_preloader_controller.js

import ApplicationController from "./application_controller";
export default class ImagesPreloaderController extends ApplicationController {

  static targets = [ 'pageImage' ]
  
  initialize() {
    window['images_preloader'] = this;
    // getting all the divs having page image urls and page numbers of all the pages in a page set
    const pageImageDivs = this.pageImageTargets.map(target => ({
      url: target.dataset.typingPageImage,
      pageNumber: target.dataset.pageNumber
    }));

    this.imageObjects = {};

    // for each div having page image url and page number, creating an image object, giving image obj a source url then loading that image obj and finally storing the loaded image obj in an array to be fetched later
    pageImageDivs.forEach(page => {
      const image = new Image();
      image.loaded = false; 
      image.onload = function() {
        this.loaded = true;
      };

      image.crossOrigin = 'anonymous' // this specific code line is crucial for displaying image, without this line loaded image fail to display
      image.src = page.url;
      this.imageObjects[page.pageNumber] = image; // storing page image against its page number
    });
  }

  isImageLoaded(pageNumber){
    if (this.imageObjects && this.imageObjects[pageNumber]) {
        return this.imageObjects[pageNumber].loaded === true;
    } else {
        return false;
    }
  }
}