// Controller for stages-menu above the canvas
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect(){
    // Named this variable as serverController bcoz we have rails controllers(server side) and js controllers(client side)
    this.serverController = this.element.dataset.serverControllerName;
    this.itemId = this.element.dataset.itemId;
    this.typing = this.element.dataset.typing;
  }

  showNotes({ created_on_type_o_to_be_checked = false , created_on_type_o_misspelled = false, created_on_type_l_to_be_checked = false, created_on_type_l_misspelled = false }) {
    Rails.ajax({
      type: "GET",
      url: `/notes.js`,
      data: `item_id=${this.itemId}&item_controller=${this.serverController}&typing=${this.typing}&created_on_type_o_to_be_checked=${created_on_type_o_to_be_checked}&created_on_type_o_misspelled=${created_on_type_o_misspelled}&created_on_type_l_to_be_checked=${created_on_type_l_to_be_checked}&created_on_type_l_misspelled=${created_on_type_l_misspelled}`
    });
  }

  //TODO: Improve: Handle submit and redirection from backend 
  submitValues(event) {
    const formSubmitButton = document.querySelector('[data-typing-form-target="formSubmitButton"]');

    if (formSubmitButton) {
      formSubmitButton.click();
    } 
  }
}
