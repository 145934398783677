window.toBool = (obj) => {
  if (obj == 'false' || obj == false || !!obj == false){
    return false
  } else {
    return true
  }
}

// https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
window.isNumeric = function(obj) {
  if (obj == null || obj == undefined)
    return false;

  return !isNaN(obj)
}

window.objectifyForm = function(formArray) {
  //serialize data function
  var returnArray = {};
  for (var i = 0; i < formArray.length; i++){
      returnArray[formArray[i]['name']] = formArray[i]['value'];
  }
  return returnArray;
}

window.scrollToCurrentSelectedItem = function({ behavior = 'instant'} = {}){
  scrollIntoView($('.js-current-selected-item')[0], {
    block: 'start',
    inline: 'nearest',
    behavior: behavior,
    boundary: $('.js-sidebar-items-list')[0]
  })
}

window.generateUniqIdentifier = function(){
  return 'a-' + Math.floor(Math.random() * Date.now());
}

window.showFlashMessage = function(message, message_type = 'error', displayPlacement = 'normal'){
  let flashMessageContainer;
  if (displayPlacement == 'float'){
    flashMessageContainer = document.getElementById("js-flash-messages-float");
  } else {
    flashMessageContainer = document.getElementById("js-flash-messages");
  }
  flashMessageContainer.innerHTML += `
    <div data-controller="flash-message">
      <div
        data-flash-message-target="input"
        data-flash-type="${message_type}"
        data-message-content="${message}"
      >
      </div>
      <div data-flash-message-target="output"></div>
    </div>
  `;
}

// TODO: Move to separate file
// Displays element(s) on top of page. On scrolling, if some items get scrolled up, those items are displayed on top of the page.
document.addEventListener('scroll', (e) => {
  let hoverElements = document.getElementsByClassName('js-always-hover');

  if(hoverElements.length <= 0)
    return;

  let cropperElement = document.getElementById('js-cropper')
  if(!cropperElement)
    return;

  let toolbarBottomYCoordinate = containerLowerYCoordinate(hoverElements[hoverElements.length - 1])
  let previousItemsHeight = 0;
  hoverElements.forEach((hoverElement) => {
    if(pageYOffset > toolbarBottomYCoordinate){
      hoverElement.style.position = 'fixed';
      hoverElement.classList.toggle("hover-show-on-top", true);
      hoverElement.style.top = `${previousItemsHeight}px`;
      hoverElement.style.width = `${cropperElement.clientWidth + 50}px`;

      previousItemsHeight += hoverElement.clientHeight;
    } else {
      hoverElement.style.position = 'relative';
      hoverElement.style.top = '';
      hoverElement.style.width = '';
      hoverElement.classList.toggle("hover-show-on-top", false);
    }
  })
});

//Method to parse string and make the links inside string clickable
window.linkify = function(inputText) {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
}

function containerLowerYCoordinate(htmlObject) {
  var bottom = 0;

  if(htmlObject){
    bottom = htmlObject.offsetTop + htmlObject.clientHeight
  }

  return bottom;
}
