// general_controller.js
import ApplicationController from "./application_controller";
import API from '../lib/api'

// General stimulus controller added for generic events
export default class GeneralController extends ApplicationController {
  static targets = []

  connect(){
    this.dictionaryId = this.element.dataset['dictionaryId']
  }

  // For marking a column and its next column
  markColumnContinuing(e) {
    let selectedRadioButton = e.currentTarget;
    let itemId = selectedRadioButton.dataset['itemId']
    let itemType = selectedRadioButton.dataset['itemType']

    API.put({
      url: `/dictionaries/${this.dictionaryId}/continuing_columns/${itemId}`,
      data: {
        is_breaking: selectedRadioButton.value,
        item_type: itemType
      }
    });
  }
}


