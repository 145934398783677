import {calculatePolygonDimensions} from "../array_utils";

const initRotationManagement = (context) => {
  context.rotatePoint = (point, centerPoint, angle) => {
    if (!centerPoint)
      centerPoint = { x: 0, y: 0 }
    var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (point.x - centerPoint.x)) + (sin * (point.y - centerPoint.y)) + centerPoint.x,
        ny = (cos * (point.y - centerPoint.y)) - (sin * (point.x - centerPoint.x)) + centerPoint.y;
    return { x: nx, y: ny }
  }

  context.rotatePolygon = (polygon, centerPoint, angle) => {
    return polygon.map((point) => context.rotatePoint(point, centerPoint, angle))
  }

  context.getRotatedDimensions = (image, angle) => {
    const rotationCenter = {
      x: image.width / 2,
      y: image.height / 2,
    }
    const imageRect = [
      {
        x: 0,
        y: 0,
      },
      {
        x: image.width,
        y: 0,
      },
      {
        x: image.width,
        y: image.height,
      },
      {
        x: 0,
        y: image.height,
      },
    ]

    const rotatedRect = context.rotatePolygon(imageRect, rotationCenter, angle)
    return calculatePolygonDimensions(rotatedRect)
  }
}

export {
  initRotationManagement
}
