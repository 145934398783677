// THEME LAYOUT JS

// If toggle button to show/hide sidebar is clicked, save that setting to  backend
const toggleButton = document.getElementsByClassName("js-sidebar-toggle")[0];
if (toggleButton){
  toggleButton.addEventListener("click", async () => {
    // Change sidebar display setting
    let sidebar = document.getElementsByClassName("js-sidebar")[0];
    let toggleFlag = 'true';
    if (sidebar)
      toggleFlag = sidebar.classList.contains('collapsed');

    Rails.ajax({
      type: "PATCH",
      url: "/user_settings/toggle_display_complete_menu.js",
      data: `toggle_flag=${toggleFlag}`
    });

    // TODO: Improve, not proper way, use await for ajax request
    await new Promise(r => setTimeout(r, 750));
    // IF ONE OF CANVAS PAGES, RELOAD PAGE AS CANVAS NEEDS TO RELOAD ON THIS SCREEN CHANGE
    if (document.getElementsByClassName("js-canvas-container").length > 0){
      location.reload();
    }
  });
} 
