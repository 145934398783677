export class CanvasScrollbar {
  x;
  y;
  xDragging;
  yDragging;
  mainContext;
  xScrollTarget;
  yScrollTarget;
  scrollbarSavedScale;
  scrollbarSavedX;
  scrollbarSavedY;
  zoomInstance;

  constructor(mainContext, xScrollTarget, yScrollTarget, zoomInstance) {
    this.xScrollTarget = xScrollTarget;
    this.yScrollTarget = yScrollTarget;
    this.mainContext = mainContext;
    this.zoomInstance = zoomInstance;
    const init = xy => {
      const clientXY = 'client' + xy.toUpperCase();
      const xyDragging = xy + 'Dragging';
      const xyScrollTarget = xy + 'ScrollTarget';
      const deltaXY = 'delta' + xy.toUpperCase();
      const notDeltaXY = 'delta' + xy.toUpperCase() === 'X' ? 'Y' : 'X';
      this[xyScrollTarget].addEventListener('mousedown', e => {
        this[xyDragging] = true;
        this[xy] = e[clientXY];
      });
      document.addEventListener('mousemove', e => {
        if (this[xyDragging] && e.buttons >= 1) {
          const deltaXYValue = (e[clientXY] - this[xy]) * mainContext.globalZoomFactor;
          this[xy] = e[clientXY];
          this.handleMove({ [deltaXY]: deltaXYValue, [notDeltaXY]: 0 }, 1);
        } else if (this[xyDragging] && e.buttons === 0) {
          this[xyDragging] = false;
          this[xy] = false;
        }
      });
      document.addEventListener('mouseup', e => {
        this[xyDragging] = false;
        this[xy] = false;
      });
    }
    init('x');
    init('y');
  }

  updateScrollbars(scaleFactor = this.scrollbarSavedScale, x = this.scrollbarSavedX, y = this.scrollbarSavedY) {
    this.scrollbarSavedScale = scaleFactor;
    this.scrollbarSavedX = x;
    this.scrollbarSavedY = y;
    if (scaleFactor > 1) {
      const xParentRect = this.xScrollTarget.parentElement.getBoundingClientRect();
      const yParentRect = this.yScrollTarget.parentElement.getBoundingClientRect();
      const xLength = this.mainContext.canvas.width / scaleFactor;
      const yLength = this.mainContext.canvas.height / scaleFactor;
      const xFactor = (xParentRect.width - xLength - 8) / (this.mainContext.canvas.width * (scaleFactor - 1));
      const yFactor = (yParentRect.height - yLength - 8) / (this.mainContext.canvas.height * (scaleFactor - 1));
      this.showScrollbars()
      this.xScrollTarget.style.width = xLength + 'px';
      this.yScrollTarget.style.height = yLength + 'px';
      this.xScrollTarget.style.left = xFactor * x + 'px';
      this.yScrollTarget.style.top = yFactor * y + 'px';
    } else if (scaleFactor <= 1) {
      this.hideScrollbars()
    }
  }

  hideScrollbars() {
    this.xScrollTarget.parentElement.classList.add('collapse');
    this.yScrollTarget.parentElement.classList.add('collapse');
  }

  showScrollbars() {
    this.xScrollTarget.parentElement.classList.remove('collapse');
    this.yScrollTarget.parentElement.classList.remove('collapse');
  }

  /**
   * @param {{ deltaX: number, deltaY: number }} e
   * @param { number } moveMultiplier
   */
  handleMove(e, moveMultiplier = 2) {
    if (this.mainContext.globalZoomFactor <= 1)
      return
    //e.movement is used to track the relative movement of the mouse for grab & scroll 
    //e.delta is used to get the scroll during wheel or mousewheel events.
    const dx = (-1 * e.movementX) || e.deltaX; // multiplied with -1 to control direction of movement
    const dy = (-1 * e.movementY) || e.deltaY;
    let globalWindowScrollStep = 30;

    const isPageAtBottom = () => {
      return ((window.innerHeight + window.scrollY) >= window.document.body.scrollHeight - 2);// document.body.offsetHeight);
    }

    const isPageAtTop = () => {
      return window.scrollY == 0
    }

    // While scrolling, if page scroll is available, we are scrolling page first, and then the canvas
    let originalYScroll = document.documentElement.scrollTop || document.body.scrollTop;
    // Reverting it for now # https://github.com/ahmetabdullah/BuildLQ/pull/151/files
    if (false && dy > 0 && !isPageAtBottom()) { // Scroll down
      window.scrollTo({ top: originalYScroll + globalWindowScrollStep })
      return;
    } else if (false && dy < 0 && !isPageAtTop()) { // Scroll Up
      window.scrollTo({ top: originalYScroll - globalWindowScrollStep })
      return;
    }

    const lastPoint = this.mainContext.currentPathLastPoint()
    let stepX = 0
    let stepY = 0
    if (lastPoint) {
      const lastPointTransformed = this.mainContext.transformFromOriginalCoordinates(lastPoint)
      if (dx < 0) {
        stepX = this.mainContext.canvas.width - lastPointTransformed.x
      } else if (dx > 0) {
        stepX = lastPointTransformed.x
      }
      if (dy < 0) {
        stepY = this.mainContext.canvas.height - lastPointTransformed.y
      } else if (dy > 0) {
        stepY = lastPointTransformed.y
      }
      stepX = Math.abs(stepX / this.mainContext.globalZoomFactor * moveMultiplier - 10);
      stepY = Math.abs(stepY / this.mainContext.globalZoomFactor * moveMultiplier - 10);
    } else {
      stepX = dx;
      stepY = dy;
      stepX = Math.abs(stepX / this.mainContext.globalZoomFactor * moveMultiplier);
      stepY = Math.abs(stepY / this.mainContext.globalZoomFactor * moveMultiplier);
    }
    while (this.zoomInstance.move({
      movingLeft: dx < 0,
      movingRight: dx > 0,
      step: stepX
    }) === false) {
      stepX--
      if (stepX <= 0)
        break
    }
    while (this.zoomInstance.move({
      movingUp: dy < 0,
      movingDown: dy > 0,
      step: stepY
    }) === false) {
      stepY--
      if (stepY <= 0)
        break
    }
  }
}
