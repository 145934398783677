import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['advanceFilterButton', 'advanceFilterContainer']

  initialize(){
  }

  connect(){
  }

  toggleAdvanceFilters(){
    this.advanceFilterContainerTarget.classList.toggle('collapse')
  }
}
