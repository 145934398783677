// utils_controller.js

// TODO: Made a separate controller for Utils, Move existing utilities functionality here
import ApplicationController from "./application_controller";
import { dragElement } from 'modules/drag_element';
export default class UtilsController extends ApplicationController {
  static targets = [
  ];

  initialize(){ }

  connect(){ }

  newPageEditRequest(evt) {
    let pageId = evt.currentTarget.dataset.pageId
    Rails.ajax({
      type: "GET",
      url: `/page_edit_requests/new.js`,
      data: `page_id=${pageId}`
    });
  }

  loadReorderColumnsContainer(_evt){
    if (cropper.currentState().unsavedChanges){
      alert('Please apply unsaved changes to load updated columns');
    } else {
      Rails.ajax({
        type: "GET",
        url: `/pages/${cropper.firstCanvas().pageId}/reorder_columns`
      });
      canvasInstances.forEach((canvasInstance) => {
        canvasInstance.zoomWidth();
      })
      // cropper.reorderColumnsContainerTarget.classList.toggle("collapse", false);
      dragElement(cropper.reorderColumnsRootTarget)
    }
  }

  resetColumnsOrder(_evt){
    cropper.autoSortEntries = true;
    cropper.sortClips()
    cropper.setUnsavedChanges();
    cropper.redraw();
  }
}
