// app/javascript/controllers/typing_preview_controller.js
import ApplicationController from "./application_controller";
import Polygon from '../lib/polygon';
export default class PreviewItemTypingController extends ApplicationController {
  static targets = ['previewImages'];

  resetLinkedImagesHeights(item) { // function to reset images heights in typing preview modals in case of combined typings
    if(item) { // checking if item parameter item is not null, in cases other than combined typings item will be null
      let maxHeight = 0;
      const linkedItems = JSON.parse(this.data.get("items")); // getting linked items in combined typing case to get max height out of all linked regions 
      if(linkedItems){
        linkedItems.forEach(linkedItem => { // iterating over linked items to get max height out of all linked items
          const height = new Polygon(linkedItem.points).height();
          if (height > maxHeight) {
            maxHeight = height;
          }
        });
        this.previewImagesTargets.forEach(image => { // iterating over all linked images in combined typing case to get image points to get image height
          const imagePoints = JSON.parse(image.getAttribute('data-preview-item-typing-imagePoints')); // getting image points to find height of image from dufference of max Y and min Y
          const imageHeight = new Polygon(imagePoints).height();
          const tabPaneId = item.dataset.tabPaneId; // active tab Id to get currently active tab showing image in preview screen 
           image.style.display = "none"; // initial restricting image to get displayed to avoid transition effect of image height increase
          setTimeout(() => { // adding delay to get image container get loaded before fetching its height, without delay its height will be 0 as it is being displayed/active dynamically  
            const divContainingImage = document.querySelector(`#${tabPaneId}`);
            const divHeight = divContainingImage.offsetHeight;
            const newHeight = imageHeight/maxHeight;
            image.style.height = `${newHeight * divHeight}px`;
            image.style.display = "block";
            
            if (divContainingImage.scrollWidth > divContainingImage.clientWidth) {
              divContainingImage.classList.remove('justify-content-center');
            } else {
              divContainingImage.classList.add('justify-content-center');
            }
          }, 250);            
        });
      }
      else {
        const image = this.previewImagesTargets[0];
        const imagePoints = JSON.parse(image.getAttribute('data-preview-item-typing-imagePoints')); // getting image points to find height of image from dufference of max Y and min Y
        const imageHeight = new Polygon(imagePoints).height(); 
        const tabPaneId = item.dataset.tabPaneId; // active tab Id to get currently active tab showing image in preview screen 
         image.style.display = "none"; // initial restricting image to get displayed to avoid transition effect of image height increase
        setTimeout(() => { // adding delay to get image container get loaded before fetching its height, without delay its height will be 0 as it is being displayed/active dynamically  
          const divContainingImage = document.querySelector(`#${tabPaneId}`);
          const divHeight = divContainingImage.offsetHeight;
          image.style.height = `${imageHeight * divHeight}px`;
          image.style.display = "block";
        }, 250);
      }
    }
  }
}