const Zoom = () => {
  const ZOOM_SCALE_FACTOR = 1.1
  return {
    init: ({canvasContext, hoverCanvas, redraw, updateScrollbars}) => {
      let lastX, lastY, previousCenter, previousMousePoint;

      const initDefaults = () => {
        lastX = canvasContext.canvas.width / 2
        lastY = canvasContext.canvas.height / 2
        previousCenter = false
        previousMousePoint = false

        canvasContext.zoomCenter   = { x: lastX, y: lastY }
        canvasContext.centerOffset = { x: 0, y: 0 }
        canvasContext.centerMoved  = { x: 0, y: 0 }
        
        canvasContext.reachedCanvasBottom = false; // to check if at the end of current canvas on screen when clicking page down button
        canvasContext.reachedCanvasTop = false; // to check if at the top of current canvas on screen when clicking page down button

        // This is the current Zoom value
        canvasContext.globalZoomFactor = 1
      }

      initDefaults()

      // Added check for zoom in entry_canvas_controller. There is no hoverCanvas there
      if(hoverCanvas){
        hoverCanvas.addEventListener('mousemove', (e) => {
          lastX = e.offsetX || (e.pageX - canvasContext.canvas.offsetLeft)
          lastY = e.offsetY || (e.pageY - canvasContext.canvas.offsetTop)
        }, false)
      }

      const zoom = (clicks, centerX, centerY, x, y) => {
        let newFactor = canvasContext.globalZoomFactor * Math.pow(ZOOM_SCALE_FACTOR, clicks)
        zoomFactor(newFactor, centerX, centerY, x, y)
      }

      const zoomFactor = (newFactor, centerX, centerY, x, y) => {
        
        const mousePoint = {
          x: x !== undefined ? x : (centerX || newFactor < 1) && (canvasContext.canvas.width / 2) || lastX,
          y: y !== undefined ? y : (centerY || newFactor < 1) && (canvasContext.canvas.height / 2) || lastY
        }
      
        let centerPoint = mousePoint
        mousePoint.x -= canvasContext.centerMoved.x
        mousePoint.y -= canvasContext.centerMoved.y
        if (!previousMousePoint)
          previousMousePoint = mousePoint

        if (previousCenter){
          centerPoint = {
            x: previousCenter.x + (mousePoint.x - previousMousePoint.x) / canvasContext.globalZoomFactor,
            y: previousCenter.y + (mousePoint.y - previousMousePoint.y) / canvasContext.globalZoomFactor
          }

          const limitFromWidth = (canvasContext.canvas.width - centerPoint.x) * Math.max(newFactor, 1)
          if (limitFromWidth < canvasContext.canvas.width - mousePoint.x - canvasContext.centerMoved.x)
            centerPoint.x -= canvasContext.canvas.width - mousePoint.x - canvasContext.centerMoved.x - limitFromWidth

          const limitFromHeight = (canvasContext.canvas.height - centerPoint.y) * Math.max(newFactor, 1)
          if (limitFromHeight < canvasContext.canvas.height - mousePoint.y - canvasContext.centerMoved.y)
            centerPoint.y -= canvasContext.canvas.height - mousePoint.y - canvasContext.centerMoved.y - limitFromHeight

          const factoredCenterX = centerPoint.x * Math.max(newFactor, 1)
          if (factoredCenterX < mousePoint.x + canvasContext.centerMoved.x)
            centerPoint.x += mousePoint.x + canvasContext.centerMoved.x - factoredCenterX

          const factoredCenterY = centerPoint.y * Math.max(newFactor, 1)
          if (factoredCenterY < mousePoint.y + canvasContext.centerMoved.y)
            centerPoint.y += mousePoint.y + canvasContext.centerMoved.y - factoredCenterY
        } else {
          previousCenter = centerPoint = mousePoint
        }

        canvasContext.translate(previousCenter.x, previousCenter.y)
        canvasContext.scale(1 / canvasContext.globalZoomFactor, 1 / canvasContext.globalZoomFactor)
        canvasContext.translate(-previousMousePoint.x, -previousMousePoint.y)
        
        canvasContext.globalZoomFactor = newFactor
        
        canvasContext.translate(mousePoint.x, mousePoint.y)
        canvasContext.scale(canvasContext.globalZoomFactor, canvasContext.globalZoomFactor)
        canvasContext.translate(-centerPoint.x, -centerPoint.y)
        canvasContext.centerOffset = {
          x: mousePoint.x - centerPoint.x,
          y: mousePoint.y - centerPoint.y
        }
        
        canvasContext.zoomCenter = previousCenter = centerPoint
        previousMousePoint = mousePoint
        redraw({ x: parseInt(lastX + canvasContext.canvas.offsetLeft), y: parseInt(lastY + canvasContext.canvas.offsetTop) })

        runUpdateScrollbars();
      }

      const initZoom = () => {
        initDefaults()
      }

      const resetZoom = () => {
        zoom(-Math.log(canvasContext.globalZoomFactor) / Math.log(ZOOM_SCALE_FACTOR));
      }

      const move = ({ movingLeft, movingUp, movingRight, movingDown, step = 1 }) => {
        let xMoved = 0
        let yMoved = 0
        if (movingLeft)
          xMoved += step
        if (movingRight)
          xMoved -= step
        if (movingUp)
          yMoved += step
        if (movingDown)
          yMoved -= step

        canvasContext.centerMoved.x += xMoved * canvasContext.globalZoomFactor
        canvasContext.centerMoved.y += yMoved * canvasContext.globalZoomFactor
        const transformedRoot = canvasContext.transformedRoot()
        const transformedRoof = canvasContext.transformedRoof()

        if (transformedRoof.x > canvasContext.canvas.width) {
          canvasContext.centerMoved.x += (transformedRoof.x - canvasContext.canvas.width) * canvasContext.globalZoomFactor;
          xMoved += (transformedRoof.x - canvasContext.canvas.width);
        }
        if (transformedRoot.x < 0) {
          canvasContext.centerMoved.x += transformedRoot.x * canvasContext.globalZoomFactor;
          xMoved += transformedRoot.x;
        }
        let additionalYMargin = canvasContext.additionalYMargin();
        let assumedCanvasHeightForScroll = canvasContext.canvas.height + additionalYMargin;
        let assumedZeroCanvasHeightForScroll = -additionalYMargin;

        if (transformedRoof.y > assumedCanvasHeightForScroll) {// if true then means reached at the bottom of current canvas
          canvasContext.reachedCanvasBottom = true;
          canvasContext.centerMoved.y += (transformedRoof.y - assumedCanvasHeightForScroll) * canvasContext.globalZoomFactor;
          yMoved += (transformedRoof.y - assumedCanvasHeightForScroll);
        }
        else {
          canvasContext.reachedCanvasBottom = false;
        }
        if (transformedRoot.y < assumedZeroCanvasHeightForScroll) {// if true then means reached at the top of current canvas
          canvasContext.reachedCanvasTop = true;
          canvasContext.centerMoved.y += (transformedRoot.y - assumedZeroCanvasHeightForScroll) * canvasContext.globalZoomFactor;
          yMoved += transformedRoot.y - assumedZeroCanvasHeightForScroll;
        }
        else {
           canvasContext.reachedCanvasTop = false;
        }

        canvasContext.translate(xMoved, yMoved)
        redraw({ x: parseInt(lastX + canvasContext.canvas.offsetLeft), y: parseInt(lastY + canvasContext.canvas.offsetTop) })
        runUpdateScrollbars()
        return true
      }

      // Move image veritically
      const moveVertically = (value = 30) => {
        if (value > 0){
          move({ movingLeft: 0, movingUp: value, movingRight: 0, movingDown: 0, step: 10 })
        } else {
          move({ movingLeft: 0, movingUp: 0, movingRight: 0, movingDown: value, step: 10 })
        }
      }

      // This method is empty but required. Code breaks without it
      const center = () => {

      }

      const runUpdateScrollbars = () => {
        const point = canvasContext.transformToOriginalCoordinates({ x: 0, y: 0 });
        updateScrollbars(canvasContext.globalZoomFactor,
          Math.max(0, Math.floor(point.x * canvasContext.globalZoomFactor)),
          Math.max(0, Math.floor(point.y * canvasContext.globalZoomFactor)));
      }

      // Most probably - transformToOriginalCoordinates and transformFromOriginalCoordinates methods transform a general point in screen to point in canvas and vice versa,
      // for the communication between the two
      canvasContext.transformToOriginalCoordinates = (point) => {
        return {
          x: (point.x - canvasContext.zoomCenter.x - canvasContext.centerOffset.x - canvasContext.centerMoved.x) / canvasContext.globalZoomFactor + canvasContext.zoomCenter.x,
          y: (point.y - canvasContext.zoomCenter.y - canvasContext.centerOffset.y - canvasContext.centerMoved.y) / canvasContext.globalZoomFactor + canvasContext.zoomCenter.y
        }
      }

      canvasContext.transformFromOriginalCoordinates = (point) => {
        return {
          x: (point.x - canvasContext.zoomCenter.x) * canvasContext.globalZoomFactor + canvasContext.zoomCenter.x + canvasContext.centerOffset.x + canvasContext.centerMoved.x,
          y: (point.y - canvasContext.zoomCenter.y) * canvasContext.globalZoomFactor + canvasContext.zoomCenter.y + canvasContext.centerOffset.y + canvasContext.centerMoved.y
        }
      }

      canvasContext.isPathInCanvas = (polygon, allPoints = true) => {
        if (allPoints){
          return polygon.every((point) => {
            return canvasContext.isClipPointInZoomedCanvas(point);
          })
        } else {
          return polygon.some((point) => {
            return canvasContext.isClipPointInZoomedCanvas(point);
          })
        }
      }

      canvasContext.transformedRoot = () => {
        return canvasContext.transformToOriginalCoordinates({ x: 0, y: 0 })
      }
      canvasContext.transformedRoof = () => {
        return canvasContext.transformToOriginalCoordinates({ x: canvasContext.canvas.width, y: canvasContext.canvas.height })
      }


      canvasContext.isClipPointInZoomedCanvas = (clipPoint) => {
        const transformedRoot = canvasContext.transformedRoot()
        const transformedRoof = canvasContext.transformedRoof()
        // clipPoint = canvasContext.transformToOriginalCoordinates(clipPoint) // Not required
        if (clipPoint.x >= transformedRoot.x && clipPoint.y >= transformedRoot.y && clipPoint.x <= transformedRoof.x && clipPoint.y <= transformedRoof.y)
          return true;
        else
          return false;
      }

      return { initZoom, zoom, zoomFactor, resetZoom, move, center, runUpdateScrollbars, moveVertically };
    }
  }
}

export default Zoom
