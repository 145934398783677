// bulk_action_controller.js
import ApplicationController from "./application_controller";
// TODO: rename SubmittedSetListController
import API from '../lib/api'

export default class extends ApplicationController {
  static targets = [
    'setsMassActions',
    'submittedSetCheckbox',
    'submittedSetInReviewCheckbox'];
  
  initialize() {
    window.bulkActionController = this;
  }

  async approve(e) {
    let currentRequestsCount = 0;
    let totalRequestsCount = this.setsMassActionsTarget.querySelectorAll('[data-bulk-action="true"]:checked').length;

    const approvalMessage = $('.js-bulk-approval-message-text-box')[0].value
    $("#bulk_approve_pageset_modal").modal("hide");
    
    const massActionsTargets = this.setsMassActionsTarget.querySelectorAll('[data-bulk-action="true"]:checked');

    for (const element of massActionsTargets) {
      let _output = await API.post({
        url: Routes.approve_set_path(element.value, { format: 'json' }),
        data: { set_approval_message: approvalMessage },
      })
      currentRequestsCount++;
      element.closest('tr').remove()
    }
    this.updateFlashMessages(currentRequestsCount, totalRequestsCount);
  }

  reject(e) {
    const returnReasonValue = $('.js-bulk-return-reason-text-box')[0].value
    if (!returnReasonValue) {
      alert('Please enter a return reason.');
      return;
    }
    $("#bulk_return_pageset_modal").modal("hide");

    this.setsMassActionsTarget.querySelectorAll('[data-bulk-action="true"]:checked').forEach(async (element) => {
      await API.post({
        url: Routes.return_set_path(element.value, { format: 'json' }),
        data: { is_bulk_request: true,
                set_return_reason: returnReasonValue },
      })
      element.closest('tr').remove()
    })
  }

  displayBulkReturnModal(e) {
    const selectedSets = this.setsMassActionsTarget.querySelectorAll('[data-bulk-action="true"]:checked');
    if (selectedSets.length == 0) {
      alert('No item selected.');
      return; // Exit the function if no set is selected
    }
    const modalTarget =  document.getElementById("bulk_return_pageset_modal");
    if (modalTarget) {
      $(modalTarget).modal('show');
    }
  }

  displayBulkApproveModal(e) {
    const selectedSets = this.setsMassActionsTarget.querySelectorAll('[data-bulk-action="true"]:checked');
    if (selectedSets.length == 0) {
      alert('No item selected.');
      return; // Exit the function if no set is selected
    }
    const modalTarget =  document.getElementById("bulk_approve_pageset_modal");
    if (modalTarget) {
      $(modalTarget).modal('show');
    }
  }

  unassignReviewerInBulkFromSets(e) {
    const selectedSets = this.setsMassActionsTarget.querySelectorAll('[data-bulk-action="true"]:checked');
    if (selectedSets.length === 0) {
      alert('No item selected.');
      return;
    }

    const setIds = Array.from(selectedSets).map(element => element.value);
    $.ajax({
      url: Routes.unassign_bulk_reviewer_page_set_review_index_path(),
      type: 'POST',
      data: {
        set_ids: setIds.join(',')
      }
    });
  }

  assignBulkReviewerToSets(e) {
    let selectedReviewerId = $('#bulk-reviewer-select').val();
    if (!selectedReviewerId) {
      alert('Please select a reviewer.');
      return;
    }
    selectedReviewerId = parseInt(selectedReviewerId, 10)
    const selectedSets = this.setsMassActionsTarget.querySelectorAll('[data-bulk-action="true"]:checked');
    if (selectedSets.length === 0) {
      alert('No item selected.');
      return;
    }
    $("#bulk_assign_reviewer_modal").modal("hide");

    const setIds = Array.from(selectedSets).map(element => element.value);
    
    $.ajax({
      url: Routes.assign_bulk_reviewer_page_set_review_index_path(),
      type: 'POST',
      data: {
        reviewer_id: selectedReviewerId,
        set_ids: setIds.join(',')
      }
    });
  }

  displayBulkAssignReviewerModal(e) {
    const selectedSets = this.setsMassActionsTarget.querySelectorAll('[data-bulk-action="true"]:checked');
    if (selectedSets.length == 0) {
      alert('No item selected.');
      return; // Exit the function if no set is selected
    }
    const modalTarget =  document.getElementById("bulk_assign_reviewer_modal");
    if (modalTarget) {
      $(modalTarget).modal('show');
    }
  }

  toggleAllSubmittedSetCheckboxes(e){
    let currentState = e.currentTarget.checked
    let allBulkCheckbox = this.submittedSetCheckboxTargets
    allBulkCheckbox.forEach((checkbox) => {
      checkbox.checked = currentState
    })
  }

  toggleAllSubmittedSetInReviewCheckboxes(e){
    e.preventDefault()
    let currentState = e.currentTarget.checked
    let allBulkCheckbox = this.submittedSetInReviewCheckboxTargets
    allBulkCheckbox.forEach((checkbox) => {
      checkbox.checked = currentState
    })
  }

  assigSetBulkAction(e){
    e.preventDefault()
    let selectedSetsIds = []
    let selectedUserId = null
    const selectedSets = this.setsMassActionsTarget.querySelectorAll('.js-selected-mass-action-checkbox:checked')
    if (selectedSets.length == 0) {
      alert('No item selected.');
      return
    } 
    let selectedUser = document.querySelector('.select-user option:checked')
    if (!selectedUser) {
      alert('No user selected')
      return
    }
    selectedUserId = selectedUser.value
    selectedSets.forEach((set) => {
      if (set.value) {
        selectedSetsIds.push(set.value)
      }
    })
    $.ajax({
      url: Routes.bulk_assign_sets_path(),
      type: 'POST',
      data: {
        selected_user_id: selectedUserId,
        selected_set_ids: selectedSetsIds
      }
    })
  }
}
