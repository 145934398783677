import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
  "userRole",
  "reviewerPermission"]

  initialize() {
    // removing reviewer permissions from user form if user is not reviewer
    if (this.userRoleTarget.value != 'reviewer') {
      this.reviewerPermissionTarget.classList.add('d-none')
    }
  }
  updateRole() {
    const selectedRole = this.userRoleTarget.value;

    if (selectedRole == 'reviewer') {
      this.reviewerPermissionTarget.classList.remove('d-none')
    } else {
      this.reviewerPermissionTarget.classList.add('d-none')
    }
  }
}