// image_context_view_called_controller.js
import ApplicationController from "./application_controller";

export default class ImageContextViewCallerController extends ApplicationController {

  initialize() {
    // To handle multiple modals on a single page
    this.imageViewModalId = this.element.dataset['modalId'] || 'imageModal'
  }

  openImageModal() { // Dead Code Added new openImageModalAjaxCall
    this.imageContextController().openImageModal();
  }

  imageContextController(){ // Dead Code Added new openImageModalAjaxCall
    let imageModalElement = document.querySelector(`#${this.imageViewModalId}`);
    let imageContextViewController = this.application.getControllerForElementAndIdentifier(
      imageModalElement, "image-context-view"
    );
    return imageContextViewController;
  }

  openImageModalAjaxCall(e){
    let data = {};
    if (e.currentTarget.dataset.contextCallFromPreview) {
      let selectedItem = document.querySelector('.js-preview-tab-content.active').querySelector('.js-preview-active-item-with-link.active');//get the current selected preview item
      data = {
        itemId: selectedItem.getAttribute('data-item-id'),
        itemType: selectedItem.getAttribute('data-item-type')
      }
    }
    else {
      data = {
        itemId: e.currentTarget.dataset.itemId,
        itemType: e.currentTarget.dataset.itemType,
     }
    }
      
    $.ajax({
      url: '/page_set/context/typing_item_context',
      type: 'GET',
      data: data
    });
  }
}
