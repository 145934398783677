import { Controller } from "stimulus"

// Approach -> Fill a hidden div with values
// On connect() -> Create and show flash message container
export default class extends Controller {
  static targets = [ "input", "output" ];

  connect() {
    this.inputTarget.dataset['flashType']
    this.outputTarget.innerHTML =
      `
        <div class="card alert border-0 border-start border-5 border-${this.cssColorClass()} alert-dismissible fade show py-2 mb-2">
          <div class="d-flex align-items-center">
            <div class="font-35 text-${this.cssColorClass()}"><i class='bx bxs-bell'></i>
            </div>
            <div class="ms-3">
              <h6 class="mb-0 text-${this.cssColorClass()}" style="text-transform: capitalize;">
                ${
                  this.flashTitle(this.inputTarget.dataset['flashType'])
                }
              </h6>
              <div>${this.inputTarget.dataset['messageContent']}</div>
            </div>
          </div>
          <button type="button" class="btn-close js-close-flash-btn" data-bs-dismiss="alert" aria-label="Close" data-action="click->flash-message#removeFlashMessage"></button>
        </div>
      `;

    // To scroll to error message 
    window.scrollTo(0,0)
  }

  // removes the flash message object (containing controller div). To fix re-appearing flash messages
  removeFlashMessage() {
    this.element.remove()
  }

  // Dont show error title for special cases
  flashTitle(flashType) {
    switch(flashType) {
      case 'set_returned':
        return '';
        break;
      case 'page_set_submitted':
        return 'Important!';
        break;
      default:
        return flashType;
    }
  }

  cssColorClass() {
    let cssClass = '';
    switch(this.inputTarget.dataset['flashType']) {
      case 'error':
        cssClass = 'danger';
        break;
      case 'alert':
        cssClass = 'warning';
        break;
      case 'notice':
        cssClass = 'primary';
        break;
      case 'success':
        cssClass = 'success';
        break;
      case 'set_returned':
        cssClass = 'danger';
      case 'page_set_submitted':
        cssClass = 'info fs-3';
    }
    return cssClass;  
  }
}
