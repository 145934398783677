import Utils from '../utils'

// Handles the clip related functionalities
const initClipsManagement = (cropper_instance) => {
  // General screens has single type of clips, but highlight stage has three different types of clips(entry, subentry, other_word)
  cropper_instance.generalHorizontalLines = [];
  cropper_instance.entriesHorizontalLines = [];
  cropper_instance.subEntriesHorizontalLines = [];
  cropper_instance.otherWordsHorizontalLines = [];

  // This sorting is used for sorting entries(highlight stage) and columns(split stage)
  cropper_instance.sortClips = () => {
    if (cropper_instance.isSplitStage()){
      cropper_instance.prepareLinesForSorting();
      let sortedClips = cropper_instance.getClips().sort(cropper_instance.horizontalLinesSort);
      sortedClips.forEach((clip, update_index) => {
        clip.number = update_index + 1; // +1 to start from 1
      });
    } else if (cropper_instance.isHighlightStage()){
      cropper_instance.prepareLinesForSorting();
      let sortedClips = cropper_instance.getClips().sort(cropper_instance.horizontalLinesSort);
      sortedClips.filter((clip) => clip.entryType === 'entry').forEach((clip, update_index) => {
        clip.number = update_index + 1; // +1 bcoz number element is used to display index
      });
      sortedClips.filter((clip) => clip.entryType === 'subentry').forEach((clip, update_index) => {
        clip.number = update_index + 1; // +1 bcoz number element is used to display index
      });
      // Assigning different numbers to different sub-categories in other
      let otherWordClips = sortedClips.filter((clip) => clip.entryType === 'other')
      cropper.otherWordIds.forEach((otherWordId) => {
        otherWordClips.filter((clip) => otherWordId == clip.otherWordCategory ).forEach((clip, update_index) => {
          clip.number = update_index + 1;
        })
      })
    }
  }

  // TODO: Refactor this method
  cropper_instance.prepareLinesForSorting = () => {
    if (cropper_instance.isSplitStage()){
      cropper_instance.generalHorizontalLines = [];
      cropper_instance.getClips().forEach((clip) => {
        let lines = cropper_instance.findRelatedLines(clip);
        let point_1 = closestToOriginPoint(clip);
        let point_2 = farthestFromOriginPoint(clip);
        if (lines.length == 0){ // Create a new line
          cropper_instance.generalHorizontalLines.push({ y1: point_1.y, y2: point_2.y })
        } else if (lines.length == 1) { // Expand y1 and y2 of line if required
          let line = lines[0];
          if (point_1.y < line.y1) { line.y1 = point_1.y };
          if (point_2.y > line.y2) { line.y2 = point_2.y };
        } else { // Merge all lines
          // greatest y1, greatest y2, make a new line, delete previous
          let smallestY1 = point_1.y;
          let largestY2 = point_2.y;
          lines.forEach((line) => {
            if(line.y1 < smallestY1) { smallestY1 = line.y1 };
            if(line.y2 > largestY2) { largestY2 = line.y2 };
          });

          // Removing previous now-overlapping lines
          cropper_instance.generalHorizontalLines = cropper_instance.generalHorizontalLines.filter((line) => {
            return (!lines.includes(line));
          });
          cropper_instance.generalHorizontalLines.push({ y1: smallestY1, y2: largestY2 })
        }
      });
    } else if (cropper_instance.isHighlightStage()) {
      // Entries
      cropper_instance.entriesHorizontalLines = [];
      cropper_instance.getClips('entry', { withCanvasIndex: true }).forEach((clip) => {
        let lines = cropper_instance.findRelatedLines(clip);
        let point_1 = closestToOriginPoint(clip);
        let point_2 = farthestFromOriginPoint(clip);
        if (lines.length == 0){ // Create a new line
          cropper_instance.entriesHorizontalLines.push({ y1: point_1.y, y2: point_2.y })
        } else if (lines.length == 1) { // Expand y1 and y2 of line if required
          let line = lines[0];
          if (point_1.y < line.y1) { line.y1 = point_1.y };
          if (point_2.y > line.y2) { line.y2 = point_2.y };
        } else { // Merge all lines
          // greatest y1, greatest y2, make a new line, delete previous
          let smallestY1 = point_1.y;
          let largestY2 = point_2.y;
          lines.forEach((line) => {
            if(line.y1 < smallestY1) { smallestY1 = line.y1 };
            if(line.y2 > largestY2) { largestY2 = line.y2 };
          });

          // Removing previous now-overlapping lines
          cropper_instance.entriesHorizontalLines = cropper_instance.entriesHorizontalLines.filter((line) => {
            return (!lines.includes(line));
          });
          cropper_instance.entriesHorizontalLines.push({ y1: smallestY1, y2: largestY2 })
        }
      });

      // Subentries
      cropper_instance.subEntriesHorizontalLines = [];
      cropper_instance.getClips('subentry', { withCanvasIndex: true }).forEach((clip) => {
        let lines = cropper_instance.findRelatedLines(clip);
        let point_1 = closestToOriginPoint(clip);
        let point_2 = farthestFromOriginPoint(clip);
        if (lines.length == 0){ // Create a new line
          cropper_instance.subEntriesHorizontalLines.push({ y1: point_1.y, y2: point_2.y })
        } else if (lines.length == 1) { // Expand y1 and y2 of line if required
          let line = lines[0];
          if (point_1.y < line.y1) { line.y1 = point_1.y };
          if (point_2.y > line.y2) { line.y2 = point_2.y };
        } else { // Merge all lines
          // greatest y1, greatest y2, make a new line, delete previous
          let smallestY1 = point_1.y;
          let largestY2 = point_2.y;
          lines.forEach((line) => {
            if(line.y1 < smallestY1) { smallestY1 = line.y1 };
            if(line.y2 > largestY2) { largestY2 = line.y2 };
          });

          // Removing previous now-overlapping lines
          cropper_instance.subEntriesHorizontalLines = cropper_instance.subEntriesHorizontalLines.filter((line) => {
            return (!lines.includes(line));
          });
          cropper_instance.subEntriesHorizontalLines.push({ y1: smallestY1, y2: largestY2 })
        }
      });

      // // OtherWords
      cropper_instance.otherWordsHorizontalLines = [];
      cropper_instance.getClips('other', { withCanvasIndex: true }).forEach((clip) => {
        let lines = cropper_instance.findRelatedLines(clip);
        let point_1 = closestToOriginPoint(clip);
        let point_2 = farthestFromOriginPoint(clip);
        if (lines.length == 0){ // Create a new line
          cropper_instance.otherWordsHorizontalLines.push({ y1: point_1.y, y2: point_2.y })
        } else if (lines.length == 1) { // Expand y1 and y2 of line if required
          let line = lines[0];
          if (point_1.y < line.y1) { line.y1 = point_1.y };
          if (point_2.y > line.y2) { line.y2 = point_2.y };
        } else { // Merge all lines
          // greatest y1, greatest y2, make a new line, delete previous
          let smallestY1 = point_1.y;
          let largestY2 = point_2.y;
          lines.forEach((line) => {
            if(line.y1 < smallestY1) { smallestY1 = line.y1 };
            if(line.y2 > largestY2) { largestY2 = line.y2 };
          });

          // Removing previous now-overlapping lines
          cropper_instance.otherWordsHorizontalLines = cropper_instance.otherWordsHorizontalLines.filter((line) => {
            return (!lines.includes(line));
          });
          cropper_instance.otherWordsHorizontalLines.push({ y1: smallestY1, y2: largestY2 })
        }
      });
    }
  }

  cropper_instance.assignNumberNewClip = (newClip) => {
    // finding its placement
    let smallestSuitableNumber = Number.POSITIVE_INFINITY;
    let newClipNumber = 0;
    cropper_instance.prepareLinesForSorting();
    cropper_instance.getClips().filter((clip) => isNumeric(clip.number) && clip.entryType == newClip.entryType && clip.otherWordCategory == newClip.otherWordCategory).forEach((clip) => {
      if (cropper_instance.horizontalLinesSort(clip, newClip) >= 0 && clip.number < smallestSuitableNumber){
        smallestSuitableNumber = clip.number;
        newClipNumber = clip.number;
      }
    });

    if(smallestSuitableNumber == Number.POSITIVE_INFINITY){
      let highestNumber = 0;
      cropper_instance.getClips().filter((clip) => isNumeric(clip.number) && clip.entryType == newClip.entryType && clip.otherWordCategory == newClip.otherWordCategory).forEach((clip) => {
        if(clip.number > highestNumber){
          highestNumber = clip.number;
        }
      });
      newClipNumber = Number(highestNumber) + 1;
    }
    newClip.number = newClipNumber;

    // Incrementing clips equal-above new clip
    cropper_instance.getClips().filter((clip) => isNumeric(clip.number) && clip.entryType == newClip.entryType && clip.otherWordCategory == newClip.otherWordCategory).forEach((clip) => {
      if(clip.number >= newClip.number && newClip != clip){
        clip.number++;
      }
    });
  }

  cropper_instance.handleClipNumberForDeletion = (deletedClip) => {
    var relatedClips = cropper_instance.getClips().filter((clip) => clip.entryType == deletedClip.entryType && clip.otherWordCategory == deletedClip.otherWordCategory).filter((clip) => {
      return (clip.number == deletedClip.number && clip.subNumber != deletedClip.subNumber);
    });

    if(relatedClips.length > 0){
      // No need to decrement other regions, just manage other related region
      let relatedClip = relatedClips[0]; // single related clip current: Feb 2022
      relatedClip.inParts = false;
      relatedClip.markedForLinking = false;
      relatedClip.subNumber = null;
    } else {
      // Decrement numbers for regions with greater number
      cropper_instance.getClips().filter((clip) => clip.entryType == deletedClip.entryType && clip.otherWordCategory == deletedClip.otherWordCategory).forEach((clip) => {
        if(clip.number > deletedClip.number){
          clip.number--;
        }
      });
    }
  }

  // De-link linked clips, clips which are inParts
  cropper_instance.separateClips = (selectedClip) => {
    var relatedClips = cropper_instance.getClips().filter((clip) => {
      return (clip.number == selectedClip.number && clip.entryType == selectedClip.entryType && clip.otherWordCategory == selectedClip.otherWordCategory);
    });
    // sorting relatedClips based on subNumber
    relatedClips = relatedClips.sort((a, b) => a.subNumber.charCodeAt(0) - b.subNumber.charCodeAt(0));

    relatedClips.forEach((relatedClip, index) => {
      relatedClip.inParts = false
      relatedClip.markedForLinking = false
      relatedClip.subNumber = null
      if (index != 0) {
        relatedClip.number = null
      }
    })
    relatedClips.forEach((relatedClip, index) => {
      if (index != 0) {
        cropper_instance.assignNumberNewClip(relatedClip);
      }
    })
  }

  cropper_instance.relatedClips = (selectedClip) => {
    var relatedClips = cropper.getClips().filter((clip) => {
      return (clip.number == selectedClip.number && clip.entryType == selectedClip.entryType && clip.otherWordCategory == selectedClip.otherWordCategory);
    });

    return relatedClips
  }

  cropper_instance.findBSubnumberClip = (clip) => {
    let linkedClips = cropper_instance.relatedClips(clip)
    let bClip = linkedClips.find(clip => clip.subNumber == 'b');
    return bClip;
  }

  // this function calculates & returns the value to be added/subtracted from current clip in iteration
  function calculateNumberDifferenceForNonSelectedClip(currentClip, markedForLinkingClips){ 
    let summation = 0;
    let parent = markedForLinkingClips[0]
    let linkedClipsBeforeCurrentClip = markedForLinkingClips.filter(clip => Number(clip.number) <= Number(currentClip.number)); // num of clips lesser than current clip in markedforlinking
    let nonDuplicateLinkedClipsCount = linkedClipsBeforeCurrentClip.filter(clip => !clip.markedForLinkingDuplicate).length;
    let linkedPrimaryClipsBeforeCurrentClip = linkedClipsBeforeCurrentClip.filter(clip => isPrimaryClip(clip));

    if(linkedPrimaryClipsBeforeCurrentClip.length > 0){ // if equal to zero, summation is 0 // case do nothing
      if(linkedPrimaryClipsBeforeCurrentClip.includes(parent) && parent.markedForLinkingDuplicate){ // case duplicate parent exists
        summation = (-1) * (nonDuplicateLinkedClipsCount) + 1   // multiplying by -1 to make it subtract, adding 1 because in case of duplicate/double parent we add 1
      } 
      else{
        summation = (-1) * (nonDuplicateLinkedClipsCount - 1) // simple case subtraction // subtracting -> (count of linked clips existing before the current clip - 1)
      }
    }
    return Number(summation);
  }

  function isSubNumberA(clip) {
    return clip.subNumber !== undefined && clip.subNumber === 'a';
  }

  function isPrimaryClip(clip) {
    return clip.subNumber == null || clip.subNumber == undefined || clip.subNumber === 'a';
  }

  function isSubNumberUndefinedOrNull(clip) {
    return clip.subNumber == undefined || clip.subNumber == null;
  }

  cropper_instance.resetLinkingFlags = (currentClip) => {
    currentClip.markedForLinking = false;
    currentClip.markedForLinkingDuplicate = false;
  }

  // TODO: to move getChildClips to clip.js when its class is made
  cropper_instance.getChildClips = function(currentClip) {
    let childClips = this.getClips().filter((clip) => {
      return clip.entryType === currentClip.entryType && 
              clip.otherWordCategory === currentClip.otherWordCategory && 
              clip.number === currentClip.number &&
              clip.subNumber !== undefined &&
              clip.subNumber !== 'a';
    });
    return childClips;
  };

  cropper_instance.sortClipsWithPrimaryAndSecondaryCases = (clips) => {
    return clips.sort((clip1, clip2) => {
      if(isPrimaryClip(clip1) && isPrimaryClip(clip2)) {
        // Compare based on number
        if (Number(clip1.number) !== Number(clip2.number)) {
          return clip1.number - clip2.number;
        }
        
        // If numbers are equal, compare based on subNumber
        if (clip1.subNumber && clip2.subNumber) {
            return clip1.subNumber.localeCompare(clip2.subNumber);
        } else if (clip1.subNumber) {
            return -1; // clip1 comes before clip2 if clip1 has subNumber
        } else if (clip2.subNumber) {
            return 1; // clip2 comes before clip1 if clip2 has subNumber
        } else {  // Both clips have no subNumber
          if (clip1.creationOrder && clip2.creationOrder) {
            return clip1.creationOrder - clip2.creationOrder;
          } else {
            // neither same numbered clips have sub numbers nor both have creation orders // case both clips are secondary with same assigned numbers
            // Sort based on horizontal lines comparison method
            cropper_instance.prepareLinesForSorting();
            return cropper_instance.horizontalLinesSort(clip1, clip2);
          }
        }
      }
      else {
        cropper_instance.prepareLinesForSorting();
        return cropper_instance.horizontalLinesSort(clip1, clip2);
      }
    });
  };

  // this method sorts the clips on basis of their numbers, if numbers are equal then on basis of sub numbers, if subnumbers are not defined(in cases of new clip creations) then on basis their creation order
  cropper_instance.sortClipsIncludingSubNumbers = (clips) => {
    return clips.sort((clip1, clip2) => {
      // Compare based on number
      if (Number(clip1.number) !== Number(clip2.number)) {
        return clip1.number - clip2.number;
      }
      
      // If numbers are equal, compare based on subNumber
      if (clip1.subNumber && clip2.subNumber) {
          return clip1.subNumber.localeCompare(clip2.subNumber);
      } else if (clip1.subNumber) {
          return -1; // clip1 comes before clip2 if clip1 has subNumber
      } else if (clip2.subNumber) {
          return 1; // clip2 comes before clip1 if clip2 has subNumber
      } else {  // Both clips have no subNumber
        if (clip1.creationOrder && clip2.creationOrder) {
          return clip1.creationOrder - clip2.creationOrder;
        } else {
          // neither same numbered clips have sub numbers nor both have creation orders // case both clips are secondary with same assigned numbers
          // Sort based on horizontal lines comparison method
          cropper_instance.prepareLinesForSorting();
          return cropper_instance.horizontalLinesSort(clip1, clip2);
        }
      }
    });
  };

    // Function to get the highest subnumber from linked clips to control/limit the loop of comparisons
  cropper_instance.highestSubnumberFromLinkedClips = function(markedForLinkingClips) {
    let highestSubnumber = 'a'; // Initialize with the lowest subnumber
    markedForLinkingClips.forEach((clip) => {
        let subnumber = clip.subNumber;
        if (subnumber > highestSubnumber) {
            highestSubnumber = subnumber;
        }
    });
    return highestSubnumber;
  }

  cropper_instance.findOverlappingAlphaClips = (clip) => {
    let clips = cropper_instance.getClips(clip.entryType)

    let overlappingClips = [];
    clips.forEach((existingClip) => {
      if (JSON.stringify(clip.polygon) == JSON.stringify(existingClip.polygon) && isSubNumberA(existingClip)){
        overlappingClips.push(existingClip)
      }
    })
    return overlappingClips
  }

  // method to find all overlaping clips of given clip
  cropper_instance.findOverlappingClips = (clip) => {
    let clips = cropper_instance.getClips(clip.entryType)
    let overlappingClips = [];
    clips.forEach((existingClip) => {
      if (JSON.stringify(clip.polygon) == JSON.stringify(existingClip.polygon) && existingClip.subNumber == clip.subNumber){
        overlappingClips.push(existingClip)
      }
    })
    return overlappingClips
  }

  // to find a's and b's and c's and so on .... of overlaping alpha clips
  cropper_instance.findClipsBySubNumber = function(clips, subNum) {
    let matchingSubnumberClips = []
    clips.forEach((clip) => {
      let linkedClips = cropper_instance.relatedClips(clip)
      let matchedSubNumClip = linkedClips.find(clip => clip.subNumber == subNum);
      if (matchedSubNumClip){
        matchingSubnumberClips.push(matchedSubNumClip);  
      }      
    })
    return matchingSubnumberClips;
  }

  // get parent by point 3 i.e. if our new clip is greater than the clip with which the new clip is being compared wrt position then new clip's parent number will be the plus one of the max num clip of immediate previous clips.
  cropper_instance.parentNumberWhenNewLinkedClipIsAfterItsCompetitor = function(clip) {
    let clips = cropper_instance.getClips(clip.entryType)
    let overlappingClips = [];
    clips.forEach((existingClip) => {
      if (JSON.stringify(clip.polygon) == JSON.stringify(existingClip.polygon) && existingClip.subNumber == clip.subNumber){
        overlappingClips.push(existingClip)
      }
    })

    let maxClip = overlappingClips.reduce((maxClip, currentClip) => {
      return currentClip.number > maxClip.number ? currentClip : maxClip;
    }, overlappingClips[0]);

    return (Number(maxClip.number) + 1);
  }  

  // this method finds and returns the number for parent clip in newly linked clips 
  cropper_instance.findParentNumber = function(firstSelectedClip, markedForLinkingClips) {
    let i = 1; // initializing index i wtih 1 because we start comairing with b's instead of 1's
    let parentClipNumber = null;
    let currentClip = null;
    let currentSubNumber = 'a';
    let highestSubnumberPossible = cropper_instance.highestSubnumberFromLinkedClips(markedForLinkingClips);
    let allPossibleSubNumbers    = [...Array(highestSubnumberPossible.charCodeAt(0) - 'a'.charCodeAt(0) + 1)].map((_, i) => String.fromCharCode('a'.charCodeAt(0) + i));
    let overlappingClips         = cropper_instance.findOverlappingAlphaClips(firstSelectedClip)
    let currentMaxClip           = overlappingClips.reduce((maxClip, currentClip) => { return currentClip.number > maxClip.number ? currentClip : maxClip; }, overlappingClips[0]);
    let minOfMaximums            = currentMaxClip.number; // to hold minimum of maximum of overlaping clips i.e. if maximums of each overlaping clips having similar subnumber are compared then the minimum maximum out of all maximums

    while(parentClipNumber == null && currentSubNumber != highestSubnumberPossible) {
      currentSubNumber = allPossibleSubNumbers[i];
      currentClip = markedForLinkingClips[i];

      let matchingSubnumberClips = cropper_instance.findClipsBySubNumber(overlappingClips, currentSubNumber)
      matchingSubnumberClips     = cropper_instance.sortClipsIncludingSubNumbers(matchingSubnumberClips);
      currentMaxClip             = matchingSubnumberClips.reduce((maxClip, currentClip) => { return currentClip.number > maxClip.number ? currentClip : maxClip; }, matchingSubnumberClips[0]);
      if (currentMaxClip.number < minOfMaximums) {
        // update minOfMaximums if its current value is greater than the currentMaxClip.number which is the clip having maximum number out all clips with similar subnumber that are currently in iteration/consideration
        minOfMaximums = currentMaxClip.number; 
      }
      cropper_instance.prepareLinesForSorting();

      matchingSubnumberClips.forEach((clip) => {
        // compairing currentClip which refers to new clip with the clip in iteration from the group of clips having same subnumbers 
        let comparisonResult = cropper_instance.horizontalLinesSort(currentClip, clip);
        if (comparisonResult > 0.0) {
          // get parent by point 3 i.e. if our new clip is greater than the clip with which the new clip is being compared wrt position then new clip's parent number will be the plus one of the max num clip of immediate previous clips
          parentClipNumber = cropper_instance.parentNumberWhenNewLinkedClipIsAfterItsCompetitor(clip);
          return parentClipNumber;
        } else if (comparisonResult < 0.0) {
            // get parent by point 2 i.e. if our new clip is lesser than the clip with which the new clip is being compared wrt position then we will update the minOfMaximums to exclude bigger numbers from next comparions
            if ( clip.number < minOfMaximums ){
              minOfMaximums = clip.number
            }
        } else if (comparisonResult == 0.0) {
          // if our new clip lies at the same position where other overlaping clips with similar number exists then update minOfMaximums equal to maximum of overlaping clips
          let overlapedClips = cropper_instance.findOverlappingClips(clip)
          let maxOfOverlapedClips = overlapedClips.reduce((maxClip, currentClip) => { return currentClip.number > maxClip.number ? currentClip : maxClip; }, overlapedClips[0]);
          if(maxOfOverlapedClips.number < minOfMaximums){
              minOfMaximums = maxOfOverlapedClips.number
          }
        }
      })
      i++;
    }
    if(parentClipNumber == null) { 
      // if parent not identified due to comparisonResult > 0.0 being always false, comparisons between all sumnumbers completed then parent number will last minOfMaximums
      parentClipNumber = (Number(minOfMaximums) + 1);
    }
    return parentClipNumber;
  }

  // When two clips are selected to link, this action is called to link them together
  cropper_instance.combineClips = () => {
    
    // fetching those clips who are marked for duplicate linking 
    let markedForLinkingDuplicateClips = cropper_instance.getClips().filter((clip) => { return (clip.markedForLinkingDuplicate); });
    
    // Seleting parent clip if parent clip has overlapping clips
    // Start1: TODO: Refactor and make a method to find number of new duplicate link clip
    let minusCase = false;
    let parentClipNumber = null; //bsOfOverlappingClips[0].number;

    let markedForLinkingClips = cropper_instance.getClips().filter((clip) => { return (clip.markedForLinking); });
    markedForLinkingClips     = cropper_instance.sortClipsWithPrimaryAndSecondaryCases(markedForLinkingClips);

    let firstSelectedClip  = markedForLinkingClips[0];
    let secondSelectedClip = markedForLinkingClips[1];

    if (firstSelectedClip.markedForLinkingDuplicate && cropper_instance.findOverlappingAlphaClips(firstSelectedClip).length > 1){
      parentClipNumber = cropper_instance.findParentNumber(firstSelectedClip, markedForLinkingClips);
    }
    // End Start1

    markedForLinkingClips = cropper_instance.sortClipsWithPrimaryAndSecondaryCases(markedForLinkingClips)
    let parentClip = markedForLinkingClips[0];

    // here making a new clip which will be a copy of clip marked for duplicate linking
    markedForLinkingDuplicateClips.forEach((clip, index) => {
      let newClip = cropper_instance.duplicateClip(clip);
      if (parentClipNumber)
        newClip.number = Number(parentClipNumber); // Temp number
      else
        newClip.number = Number(clip.number) + 1; // givig new clip a number which is one plus number of the clip from which it is copied 
      // if(isSubNumberA(clip)){ // Temp subnumber
      //   newClip.subNumber = clip.subNumber;
      // }
      if(clip == parentClip){ // Temp subnumber
        //newClip.number = Number(parentClip.number) + 1;
        newClip.subNumber = "a";
      } else {
        newClip.subNumber = "z"
      }
      newClip.markedForLinking          = true;
      newClip.markedForLinkingDuplicate = true;
      newClip.creationOrder             = Number(index + 1) // giving creation order to sort new clips in order of their creation
      clip.markedForLinkingDuplicate = false; // setting the clip flags to false as new clone clip is created in their place
      clip.markedForLinking          = false; // setting the clip flags to false as new clone clip is created in their place
      canvasInstances[newClip.canvasIndex].mainContext.clips.push(newClip)
    });
    
    markedForLinkingClips    = cropper_instance.getClips().filter((clip) => { return (clip.markedForLinking); });

    let notMarkedForLinkingClips = cropper_instance.getClips(firstSelectedClip.entryType).filter((clip) => { return (!clip.markedForLinking); });

    // START
    cropper_instance.prepareLinesForSorting();
    markedForLinkingClips = cropper_instance.sortClipsWithPrimaryAndSecondaryCases(markedForLinkingClips);
    parentClip = markedForLinkingClips[0]; // updated parent clip, at first the parent was the clip extracted from marked for linking(the first one after sorting), now update is required in case(if markedForLinking is updated according to duplicates scenerio) then parent has to be updated such that now parent is the new duplicate parent clip  

    markedForLinkingClips.forEach((markedForLinkingClip, index) => {
      // clip.number have already be assigned to new duplicate clips on time of their creation
      markedForLinkingClip.subNumber = String.fromCharCode(index + 97)
      markedForLinkingClip.inParts = true
    })

    let fartherClip;
    fartherClip = markedForLinkingClips[markedForLinkingClips.length - 1];
    
    // handling cases for non selected clips i.e. not marked for linking clips
    notMarkedForLinkingClips.forEach(clip => {
      let summation = calculateNumberDifferenceForNonSelectedClip(clip, markedForLinkingClips);
      if (isSubNumberA(clip)) {  // If clip's subNumber is 'a', it's a parent clip and all children will get parent's updated number
        let childClips = cropper_instance.getChildClips(clip);
        clip.number = Number(clip.number) + summation;
        childClips.forEach(childClip => {
          // Update the number of each child clip according to the updated number of its parent
          childClip.number = clip.number;
        });
      } else if (isSubNumberUndefinedOrNull(clip)) {
        // If clip has no subNumber, it's neither parent nor child
        clip.number = Number(clip.number) + summation;
      }
      cropper_instance.resetLinkingFlags(clip);
    });
    // Assigning numbers to markedForLinking clips
    markedForLinkingClips.forEach(clip => {
      if (clip === parentClip) {
        // Do nothing, maintain number
      } else {
        // Non-parent clip will get its parent number
        clip.number = parentClip.number;
      }
      cropper_instance.resetLinkingFlags(clip);
    });
  }

  cropper_instance.findRelatedLines = (clip) => {

    let relatedLines = cropper_instance.getSortingHorizontalLines(clip);
    // Todo: Explain what this filter is doing
    // This is probably filtering lines on which the current clip's coordinates overlap (with error margin)
    relatedLines = relatedLines.filter((line) => {
      let point_1 = closestToOriginPoint(clip);
      let point_2 = farthestFromOriginPoint(clip);

      let errorMargin = (line.y2 - line.y1) * 0.25;
      return (
        (point_1.y >= line.y1 && point_1.y <= line.y2 - errorMargin) ||
        (point_2.y >= line.y1 + errorMargin && point_2.y <= line.y2) ||
        (point_1.y <= line.y1 && point_2.y >= line.y2)
      )
    });
    return relatedLines;
  }

  // Returing array reference, to also be able to change values through it
  cropper_instance.getSortingHorizontalLines = (clip) => {
    if (!cropper_instance.isHighlightStage()){
      return cropper_instance.generalHorizontalLines;
    } else { // Highlight stage
      if (clip.entryType == 'entry'){
        return cropper_instance.entriesHorizontalLines;
      } else if (clip.entryType == 'subentry') {
        return cropper_instance.subEntriesHorizontalLines;
      } else {
        return cropper_instance.otherWordsHorizontalLines;
      }
    }
  }

  cropper_instance.horizontalLinesSort = (clip_a, clip_b) => {
    let line_a = cropper_instance.findRelatedLines(clip_a)[0];
    let line_b = cropper_instance.findRelatedLines(clip_b)[0];
    let point_a = closestToOriginPoint(clip_a);
    let point_b = closestToOriginPoint(clip_b);
    if (line_a.y1 == line_b.y1){
      if (cropper_instance.dictionaryColumnsDirection == 'ltr'){
        return point_a.x - point_b.x;
      } else {
        return point_b.x - point_a.x;
      }
    } else {
      return line_a.y1 - line_b.y1;
    }
  }

  cropper_instance.duplicateClip = (clip) => {
    let clonedClip = _.cloneDeep(clip)
    clonedClip.id = undefined;
    clonedClip.inParts = false;
    clonedClip.markedForLinking = false;
    clonedClip.markedForLinkingDuplicate = false;
    clonedClip.number = undefined;
    clonedClip.subNumber = undefined;
    return clonedClip;
  }
}

const closestToOriginPoint = (clip) => {
  let closestCoordinate = clip.multiCanvasPolygon[0];
  clip.multiCanvasPolygon.forEach((polygonCoordinates) => {
    if(polygonCoordinates.y < closestCoordinate.y){
      closestCoordinate = polygonCoordinates;
    } else if (polygonCoordinates.y == closestCoordinate.y && polygonCoordinates.x > closestCoordinate.x) {
      closestCoordinate = polygonCoordinates;
    }
  });
  return closestCoordinate;
}

const farthestFromOriginPoint = (clip) => {
  let farthestCoordinate = clip.multiCanvasPolygon[0];
  clip.multiCanvasPolygon.forEach((polygonCoordinates) => {
    if(polygonCoordinates.y > farthestCoordinate.y){
      farthestCoordinate = polygonCoordinates;
    } else if (polygonCoordinates.y == farthestCoordinate.y && polygonCoordinates.x < farthestCoordinate.x) {
      farthestCoordinate = polygonCoordinates;
    }
  });
  return farthestCoordinate;
}

export {
  initClipsManagement
}