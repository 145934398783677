import {calculatePolygonDimensions} from "../array_utils";

// Transforms coordinates/points image points(backend) and view coordinates(used in frontend to display values)
const initCoordinatesTransformer = (context) => {
  context.scaledPoint = (point, minX, minY) => {
    if (!minX || !minY) {
      if (context.parentPolygon.length)
        ({ minX, minY } = calculatePolygonDimensions(context.parentPolygon));
      else {
        minX = 0;
        minY = 0;
      }
    }
    const ratio = context.imageRatio()
    const canvasHorizontalMarginActual = context.canvasHorizontalMarginActual()
    const canvasVerticalMarginActual = context.canvasVerticalMarginActual()
    return {
      x: (point.x - canvasHorizontalMarginActual) * ratio + minX,
      y: (point.y - canvasVerticalMarginActual) * ratio + minY
    }
  }

  context.scaledPath = (path) => {
    var minX = 0, minY = 0
    if (context.parentPolygon.length)
      var { minX, minY } = calculatePolygonDimensions(context.parentPolygon)
    return path.map((point) => context.scaledPoint(point, minX, minY))
  }

  context.unscaledPath = (path) => {
    let minX = 0, minY = 0;
    if (context.parentPolygon.length)
      ({ minX, minY } = calculatePolygonDimensions(context.parentPolygon));
    return path.map((p) => {
      return {
        x: (p.x - minX) / context.imageRatio() + context.canvasHorizontalMarginActual(),
        y: (p.y - minY) / context.imageRatio() + context.canvasVerticalMarginActual()
      }
    })
  }

  context.imageRatio = () => {
    const { width } = context.getRotatedDimensions(context.image, context.currentRotation)
    return width / context.imageCanvasWidth()
  }

  // context.canvas.width equals to image width being displayed + 2 * canvasHorizontalMargin
  context.imageCanvasWidth = () => {
    let canvasHorizontalMargin = context.canvasHorizontalMargin;
    return context.canvas.width - 2 * canvasHorizontalMargin
  }

  // context.canvas.width equals to image width being displayed + 2 * canvasHorizontalMargin
  context.imageCanvasHeight = () => {
    let canvasVerticalMargin = context.canvasVerticalMargin;
    return context.canvas.height - 2 * canvasVerticalMargin
  }
}

export {
  initCoordinatesTransformer
}
