// action_delegator_controller.js
// Element(source) will have a data attribute target, that will be pointing to the target through its ID
// TODO: ADD example here
import ApplicationController from "./application_controller";

export default class ActionDelegatorController extends ApplicationController {
  static targets = [
  ]

  connect(){
    let actionTargetId = this.element.dataset.actionTarget;
    this.actionTarget = document.querySelector(`#${actionTargetId}`);
  }

  // Called on click
  selectTarget(e) {
    if (e.target.dataset.dictionaryPagesTarget === 'dictionaryPageNumberInput') {
      return;
    }
    e.preventDefault();
    // Default source action is ctrl+click
    // Default target response is click
    if (e.ctrlKey) {
      this.actionTarget.click();
    }
  }
}
