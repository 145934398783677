import { Controller } from 'stimulus'

class ScrollbarContainer {
  containerTarget;
  scrollbarGrown = false;
  oldIndex = 0;
  mainTimeout;

  constructor(container) {
    this.containerTarget = container;
  }

  updateScrollbar(mouseX, mouseY) {
    const diffY = this.containerTarget.getBoundingClientRect().y + this.containerTarget.offsetHeight - mouseY,
        inY = diffY > 0 && diffY <= this.containerTarget.offsetHeight,
        diffX = this.containerTarget.getBoundingClientRect().x + this.containerTarget.offsetWidth - mouseX,
        inX = diffX > 0 && diffX <= 15;
    if (inY && inX && !this.scrollbarGrown) {
      this.scrollbarGrown = true;
      this.animateSrollbar(true).then();
    } else if ((!inY || !inX) && this.scrollbarGrown) {
      this.scrollbarGrown = false;
      this.animateSrollbar(false).then();
    }
  }

  async animateSrollbar(grow, time = 200, steps = 5) {
    clearTimeout(this.mainTimeout);
    const period = time / steps;
    const classes = ['zero', 'one', 'two', 'three', 'four', 'five'];
    for (let i = this.oldIndex + (grow ? 1 : -1); i <= steps && i >= 0; i += (grow ? 1 : -1)) {
      await this.timeout(period);
      requestAnimationFrame(() => {
        this.containerTarget.classList.remove(classes[this.oldIndex]);
        this.containerTarget.classList.add(classes[i]);
        this.oldIndex = i;
      });
    }
  }

  async timeout(millis) {
    return new Promise(resolve => this.mainTimeout = setTimeout(resolve, millis));
  }
}

export default class extends Controller {
  static targets = ['container'];

  initialize() {
    const scrollbarContainer = new ScrollbarContainer(this.containerTarget);
    document.addEventListener('mousemove', e => {
      scrollbarContainer.updateScrollbar(e.pageX, e.pageY);
    })
  }
}
