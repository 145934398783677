// Inspired from here https://gis.stackexchange.com/questions/216977/how-to-split-the-polygon-operation-using-of-jsts
const split = function(localPolygon, separatingLine) {
  // First transforming values according to the jsts library format

  // Sample Polygon String: 'POLYGON ((1 1, 1 9, 9 9, 9 1, 1 1))' 
  let polygonString = ''
  localPolygon.forEach((point, index) => {
    polygonString += `${point.x} ${point.y}`
    polygonString += ', '
  })
  polygonString += `${localPolygon[0].x} ${localPolygon[0].y}`
  polygonString = `POLYGON ((${polygonString}))`

  // Sample Line String: 'LINESTRING (0.5 5, 9.6 5, 9.6 3.9)' 
  let lineString = ''
  separatingLine.forEach((point, index) => {
    lineString += `${point.x} ${point.y}`
    if(index != (separatingLine.length - 1))
      lineString += ', '
  })
  lineString = `LINESTRING (${lineString})`  

  var reader = new jsts.io.WKTReader();
  var writer = new jsts.io.WKTWriter();

  var a = reader.read(polygonString);
  var b = reader.read(lineString);
  var union = a.getExteriorRing().union(b);

  var polygonizer = new jsts.operation.polygonize.Polygonizer();
  polygonizer.add(union);

  var jstsPolygons = polygonizer.getPolygons();
  let buildlqPolygons = []
  for (var i = jstsPolygons.iterator(); i.hasNext();) {
    var polygon4 = i.next();
    let buildlqPolygon = polygon4.getCoordinates().map((point) => { return { 'x': point.x, 'y': point.y }})
    buildlqPolygon.pop() // Removing the last element(point), as it is the same point. Other libraries use first and last same points, whereas we dont
    buildlqPolygons.push(buildlqPolygon)
  }

  // Sorting based on minimum Y to Order the regions in the column
  buildlqPolygons.sort((p1, p2) => {
    return Math.min.apply(Math, p1.map((p) => p.y)) - Math.min.apply(Math, p2.map((p) => p.y))
  })

  return buildlqPolygons;
}

const PolygonDivider = () => {
  return {
    split: split
  }
}

export default PolygonDivider();
