import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  /*actually we are moving forward to next partial by using targets, these targets are:
      basic, bank, student, workStatus, program, signup, maxQualification, note, password, postDoc, phd, highSchool, elementarySchool,
       associateDegree, bachelor, masterDegree, work, title, secondarySchool*/ 
  static targets = [
    'basic',
    'bank',
    'student',
    'workStatus',
    'program',
    'signup',
    'maxQualification',
    'note',
    'password',
    'postDoc',
    'phd',
    'highSchool',
    'associateDegree',
    'bachelor',
    'masterDegree',
    'work',
    'workingStatus',
    'studyProgram',
    'studentStatus',
    'title',
    'titleStatus',
    'secondarySchool',
    'elementarySchool'
  ]

  initialize(){
    window['user_render'] = this
    this.stage = 'basic';
    // this stack helps to follow the previous path of stages
    this.previousStagesStack = [];
    this.previousStagesStack.push(this.stage);
  }

  submitCurrentStage(){
    let formData = objectifyForm($(user_render.signupTarget).serializeArray())
    let userData = {
      state: this.stage,
      ...formData
    }
    $.ajax({
      dataType: 'json',
      url: Routes.sign_up_stage_validator_users_path(),
      type: 'GET',
      data: userData,
      success: function(data){
        if (data.success){
          user_render.moveToNextStage();
        } else {
          user_render.handleFailure(data);
        }
      },
      error: function(xhr, status, error){
        user_render.addFlashMessage('An unexpected error has occurred.')
      }
    });
  }

  // Options can be array or hash
  setDropdownOptions(target, options){
    let isOptionsArray = _.isArray(options)
    if(isOptionsArray){
      options.forEach((option_text) => {
        let option = document.createElement('option')
        option.value = option_text
        option.text  = option_text
        target.add(option)
      })
    } else {
      for (const key of Object.keys(options)) {
        let option_text = options[key]
        let option = document.createElement('option')
        option.value = key
        option.text  = option_text
        target.add(option)
      }
    }
  }

  moveToPreviousStage(){
    if (this.previousStagesStack.length > 0) {
      let prevStage = this.previousStagesStack.pop();
      this.stage = prevStage;

      this.showCurrentSection()
    }
  }

  // Move to next stage like (basic, bank, student_status, workStatus etc)
  moveToNextStage(){
    this.previousStagesStack.push(this.stage);
    switch (this.stage) {
    case 'basic':
      // this.stage = 'bank';
      // TODO: #81_97 Temporary skipping all other stages
      this.stage = 'password'
      break;
    case 'bank':
      this.stage = 'student_status';
      break;
    case 'student_status':
      switch(user_render.studentStatusTarget.value){
      case 'never_student':
        this.stage = 'workStatus'
        break;
      case 'graduated':
        let options = { elementary: 'Elementary School', secondary: 'Secondary School', highschool: 'High School', associate: 'Associate Degree', bachelors: "Bachelor's Degree", masters: "Master's Degree", phd: 'PhD', post_doc: 'Post-Doc' }
        user_render.setDropdownOptions(user_render.studyProgramTarget, options)
        this.stage = 'studyProgram';
        break;
      case 'current_student':
        options = { highschool: 'High School', associate: 'Associate Degree', bachelors: "Bachelor's Degree", masters: "Master's Degree", phd: 'PhD', post_doc: 'Post-Doc' }
        user_render.setDropdownOptions(user_render.studyProgramTarget, options)
        this.stage = 'studyProgram';
        break;
      }
      break;
    case 'studyProgram':
      switch(user_render.studyProgramTarget.value){
      case 'elementary':
        this.stage = 'elementarySchool';
        break; 
      case 'secondary':
        this.stage = 'secondarySchool';
        break;
      case 'highschool':
        this.stage = 'highSchool';
        break;
      case 'associate':
        this.stage = 'associateDegree';
        break;
      case "bachelors":
        this.stage = 'bachelor';
        break;
      case "masters":
        let options = ['Course Assistant', 'Reasearch Assistant', 'other']
        user_render.setDropdownOptions(user_render.titleStatusTarget, options)
        this.stage = 'masterDegree';
        break;
      case 'phd':
        options = ['Course Assistant', 'Research Assistant', 'Dr.', 'Assistan Professor', 'Assoc Professor', 'Professor', 'other']
        user_render.setDropdownOptions(user_render.titleStatusTarget, options)
        this.stage = 'phd';
        break;
      case 'post_doc':
        options = ['Course Assistan', 'Reasearch Assissten', 'Dr.', 'Assistan Professor', 'Assoc Professor', 'Professor', 'other']
        user_render.setDropdownOptions(user_render.titleStatusTarget, options)
        this.stage = 'postDoc';
        break;
      }
      break;
    case 'postDoc':
      this.stage = 'phd'
      break;
    case 'phd':
      this.stage = 'masterDegree'
      break;
    case 'masterDegree':
      this.stage = 'bachelor'
      break;
    case 'bachelor':
      this.stage = 'title'
      break;
    case 'associateDegree':
      this.stage = 'workStatus'
      break;
    case 'highSchool':
      this.stage = 'workStatus'
      break;
    case 'secondarySchool':
      this.stage = 'workStatus'
      break;
    case 'elementarySchool':
      this.stage = 'workStatus'
      break;
    case 'title':
      this.stage = 'workStatus'
      break;
    case 'workStatus':
      switch(user_render.workingStatusTarget.value){
      case 'Yes':
        this.stage = 'workInfo'
        break;
      case 'No':
        this.stage = 'note'
        break;
      }
      break;
    case 'workInfo':
      this.stage = 'note'
      break;
    case 'note':
      this.stage = 'password'
      break; 
    }
    this.showCurrentSection()
  }

  // Stays on the same stage
  handleFailure(responseData){
    let error_message = ''
    if(responseData.errors)
      error_message = responseData.errors.join(', ')
    else
      error_message = 'An unexpected error has occurred.'

    this.addFlashMessage(error_message)
  }

  showCurrentSection(){
    // Hide all sections
    this.basicTarget.classList.add('collapse')
    this.bankTarget.classList.add('collapse')
    this.studentTarget.classList.add('collapse')
    this.programTarget.classList.add('collapse')
    this.postDocTarget.classList.add('collapse')
    this.phdTarget.classList.add('collapse')
    this.masterDegreeTarget.classList.add('collapse')
    this.bachelorTarget.classList.add('collapse')
    this.associateDegreeTarget.classList.add('collapse')
    this.highSchoolTarget.classList.add('collapse')
    this.secondarySchoolTarget.classList.add('collapse')
    this.elementarySchoolTarget.classList.add('collapse')
    this.titleTarget.classList.add('collapse')
    this.workStatusTarget.classList.add('collapse')
    this.workTarget.classList.add('collapse')
    this.noteTarget.classList.add('collapse')
    this.passwordTarget.classList.add('collapse')

    switch (this.stage) {
    case 'basic':
      this.basicTarget.classList.remove('collapse')
      break;
    case 'bank':
      this.bankTarget.classList.remove('collapse')
      break;
    case 'student_status':
      this.studentTarget.classList.remove('collapse')
      break;
    case 'workStatus':
      this.workStatusTarget.classList.remove('collapse')
      break;
    case 'studyProgram':
      this.programTarget.classList.remove('collapse')
      break;
    case 'elementarySchool':
      this.elementarySchoolTarget.classList.remove('collapse')
      break;
    case 'secondarySchool':
      this.secondarySchoolTarget.classList.remove('collapse')
      break;
    case 'highSchool':
      this.highSchoolTarget.classList.remove('collapse')
      break;
    case 'associateDegree':
      this.associateDegreeTarget.classList.remove('collapse')
      break;
    case 'bachelor':
      this.bachelorTarget.classList.remove('collapse')
      break;
    case 'masterDegree':
      this.masterDegreeTarget.classList.remove('collapse')
      break;
    case 'phd':
      this.phdTarget.classList.remove('collapse')
      break;
    case 'postDoc':
      this.postDocTarget.classList.remove('collapse')
      break;
    case 'title':
      this.titleTarget.classList.remove('collapse')
      break;
    case 'workInfo':
      this.workTarget.classList.remove('collapse')
      break;
    case 'note':
      this.noteTarget.classList.remove('collapse')
      break;
    case 'password':
      this.passwordTarget.classList.remove('collapse')
      break;
    }
  }
}
