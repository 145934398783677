import Utils from '../utils'

// It handles the state management for the cropper. Statment manages the undo/redo options.
// It adds state management methods to the cropper instance
const initStateManagement = (cropper_instance) => {
  cropper_instance.currentStateIndex = 0
  cropper_instance.state = [{
    clips: [],
    currentPath: [],
    slicingPaths: []
  }]

  cropper_instance.pushState = (rewrite, unsavedChanges) => {
    const newState = Utils.copyObject({
      clips: cropper_instance.stateClips(),
      currentPath: cropper_instance.stateCurrentPaths(),
      slicingPaths: cropper_instance.stateSlicingPaths(),
      deletedClips: cropper_instance.stateDeletedClips(),
      unsavedChanges,
    })
    cropper_instance.state = cropper_instance.state.slice(0, cropper_instance.currentStateIndex + 1);
    if (rewrite) {
      cropper_instance.state[cropper_instance.currentStateIndex] = newState
    } else {
      cropper_instance.state.push(newState)
      cropper_instance.currentStateIndex += 1
    }
    if (!cropper_instance.state[cropper_instance.currentStateIndex].unsavedChanges) {
      cropper_instance.state.forEach((state, index) => {
        if (index !== cropper_instance.currentStateIndex) {
          state.unsavedChanges = true;
        }
      });
    }
  }

  cropper_instance.currentState = () => {
    return cropper_instance.state[cropper_instance.currentStateIndex]
  }

  cropper_instance.changeState = (step = 1) => {
    const proposedState = cropper_instance.currentStateIndex + step
    if (proposedState < 0 || proposedState >= cropper_instance.state.length)
      return false
    cropper_instance.currentStateIndex = proposedState
    const currentState = Utils.copyObject(cropper_instance.currentState())

    cropper_instance.setStateClips(currentState.clips)
    cropper_instance.setStateCurrentPaths(currentState.currentPath)
    cropper_instance.setStateSlicingPaths(currentState.slicingPaths)
    cropper_instance.setStateDeletedClips(currentState.deletedClips)
    return true
  }

  cropper_instance.undo = () => {
    return cropper_instance.changeState(-1)
  }

  cropper_instance.redo = () => {
    return cropper_instance.changeState(1)
  }

  cropper_instance.resetUndo = () => {
    cropper_instance.state.splice(
      cropper_instance.currentStateIndex + 1,
      cropper_instance.state.length - (cropper_instance.currentStateIndex + 1),
    )
  }

  cropper_instance.stateClips = () => {
    let stateClips = []
    canvasInstances.forEach(canvasInstance => {
      stateClips.push(canvasInstance.mainContext.clips);
    })
    return stateClips;
  }

  cropper_instance.setStateClips = (stateClips) => {
    canvasInstances.forEach((canvasInstance, index) => {
      canvasInstance.mainContext.clips = stateClips[index];
    })
  }

  cropper_instance.stateCurrentPaths = () => {
    let stateCurrentPaths = []
    canvasInstances.forEach(canvasInstance => {
      stateCurrentPaths.push(canvasInstance.mainContext.currentPath);
    })
    return stateCurrentPaths;
  }

  cropper_instance.setStateCurrentPaths = (currentPaths) => {
    canvasInstances.forEach((canvasInstance, index) => {
      canvasInstance.mainContext.currentPath = currentPaths[index];
    })
  }

  cropper_instance.stateSlicingPaths = () => {
    let stateSlicingPaths = []
    canvasInstances.forEach(canvasInstance => {
      stateSlicingPaths.push(canvasInstance.mainContext.slicingPaths);
    })
    return stateSlicingPaths;
  }

  cropper_instance.setStateSlicingPaths = (slicingPaths) => {
    canvasInstances.forEach((canvasInstance, index) => {
      canvasInstance.mainContext.slicingPaths = slicingPaths[index];
    })
  }

  cropper_instance.stateDeletedClips = () => {
    let stateDeletedClips = []
    canvasInstances.forEach(canvasInstance => {
      stateDeletedClips.push(canvasInstance.mainContext.deletedClips);
    })
    return stateDeletedClips;
  }

  cropper_instance.setStateDeletedClips = (deletedClips) => {
    canvasInstances.forEach((canvasInstance, index) => {
      canvasInstance.mainContext.deletedClips = deletedClips[index];
    })
  }
}

export {
  initStateManagement
}
