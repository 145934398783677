// This controller is derived from canvas_controller, with only some of its functionalities
// Inheritance could be used better use these two controllers
// Ticket: https://trello.com/c/Umu93lPn/356-parts-which-are-not-included-by-the-polygon-are-not-to-be-shown-typeo

// TODO: rename
// entry_canvas_controller.js
import ApplicationController from "./application_controller";
import Polygon from '../lib/polygon'
import Zoom from '../lib/zoom'
import { initUtils } from '../lib/canvas_utils'
import { CanvasScrollbar } from "../lib/canvas_scrollbar";

export default class EntryCanvasController extends ApplicationController {
  static targets = [
    'mainCanvas',
    'container',
    'xScroll',
    'yScroll',
  ];

  // Gets called in initialize()
  #assignDefaultValues(){
    this.originalImagePath = ''
    this.mainImage = new Image()
    this.parentImage = new Image()
    this.zoomInstance = {}
  }

  // item_for_typing possibilities: item_type_typing(object) 
  initialize() {
    this.#assignDefaultValues();
    this.originalImagePath = this.element.dataset.originalImage;
    this.item_for_typing   = this.element.dataset.entryType;
    this.pageNumber = this.element.dataset.pageNumber;

    this.mainContext = this.mainCanvasTarget.getContext('2d')
    initUtils(this.mainContext)

    this.mainContext.parentPolygons = JSON.parse(this.element.dataset.parentPolygons)
    this.mainContext.parentPolygon  = this.mainContext.parentPolygons[this.mainContext.parentPolygons.length - 1] || []
    
    this.mainImage.crossOrigin = 'anonymous'
    this.mainImage.onload = () => this.imageOnLoad()

    this.mainContext.blurSize = 0
    this.parentImage.crossOrigin = 'anonymous'

    // checking if image is preloaded, if preloaded then fetch and utilize it instead of loading again otherwise load the image
    if(images_preloader && images_preloader.isImageLoaded(this.pageNumber)){
      this.parentImage = images_preloader.imageObjects[this.pageNumber];
      this.mainContext.parentImage = this.parentImage
      this.mainImage.src = this.mainContext.contextualRect()
    } else {
      this.parentImage.onload = () => {
        this.mainContext.parentImage = this.parentImage
        this.mainImage.src = this.mainContext.contextualRect()
      }
      this.parentImage.src = this.originalImagePath  
    }  

    this.#initializeZoom();
  }

  #initializeZoom(){
    this.zoomInstance = Zoom().init({
      canvasContext: this.mainContext,
      redraw: (mousePoint = null) => {
        this.redraw()
      },
      updateScrollbars: (...args) => this.canvasScrollbar.updateScrollbars(...args)
    })
    this.canvasScrollbar = new CanvasScrollbar(this.mainContext, this.xScrollTarget, this.yScrollTarget, this.zoomInstance);
  }

  async imageOnLoad() {
    this.mainContext.canvas.classList.remove('w-100', 'h-100');

    this.mainContext.image = this.mainImage
    let canvasImageDimensions               = this.mainContext.fitImageInCanvas(this.mainImage, this.containerTarget.parentNode, { moveCanvasToBottom: true })
    this.mainContext.canvasHorizontalMargin = canvasImageDimensions.canvasHorizontalMargin;
    this.mainContext.canvasVerticalMargin   = canvasImageDimensions.canvasVerticalMargin;

    this.mainContext.blurOffset = this.mainContext.blurSize / this.mainContext.imageRatio()
    this.zoomInstance.initZoom()

    this.redraw()

    const clips = JSON.parse(this.element.dataset.clips)
    const newClips = []
    for (let clip of clips) {
      newClips.push({
        id: clip.id,
        polygon: this.mainContext.unscaledPath(clip.polygon),
        scaledPolygon: clip.scaledPolygon,
        data: this.mainContext.getClippedDataUrl(clip.polygon, this.mainContext.parentImage),
        entryType: clip.entryType,
        number: clip.number,
        subNumber: clip.subNumber,
        inParts: !!clip.subNumber,
        regionId: clip.regionId,
        otherWordCategory: clip.otherWordCategoryId,
      });
    }

    this.mainContext.clips = newClips;
    // this.zoomInstance.initZoom()

    this.centerAlignEntry()
    this.redraw()
    // this.zoomFitWidth();
    // this.redraw()
  }

  centerAlignEntry(){
    // X-axis alignment
    let clip          = this.mainContext.clips[0]
    let polygon       = new Polygon(clip.polygon);
    let itemCenterX   = polygon.centerX();
    let regionCenterX = this.mainContext.canvas.clientWidth / 2;
    let difference    = regionCenterX - itemCenterX;
    
    // no need to add horizontal margin for combined word, show in center
    if (this.item_for_typing != 'combined_word')
      this.containerTarget.style.marginLeft = `${difference}px`;

    let containerHeight = this.containerTarget.clientHeight;
    let entryHeight = polygon.height();

    let itemsHeigth = (this.mainContext.clips.length > 1) ? this.mainCanvasTarget.clientHeight : polygon.height();
    if(itemsHeigth < containerHeight){
      let diff = containerHeight - itemsHeigth;
      let marginTop = (diff / 2);

      if(this.mainContext.clips.length <= 1){
        marginTop -= polygon.minY(); // subtracting clip's MinY, because this margin is automatically added on canvas, and we are moving canvas here, not exact clip
      }
      this.mainCanvasTarget.style.marginTop = `${marginTop}px`;
    }
  }

  // Remove with zoom
  zoomFitWidth({ zoomFactor = undefined, initialY = undefined } = {}) {
    // Added this factor to avoid scroll-bar above the image # https://trello.com/c/ueZJ6eMc/238-cannot-highlight-from-top-right-corner
    let fitWidthFactor = 0.97;
    this.zoomFitHeight();
    if(zoomFactor == undefined){
      zoomFactor = Math.max(1, this.mainContext.canvas.width / this.mainImage.width * (this.mainContext.imageRatio() * fitWidthFactor)) // TODO: What is this code doing ??
    }
    this.zoomInstance.zoomFactor(zoomFactor, true, false, undefined, initialY);
  }

  // Remove with zoom
  zoomFitHeight(x) {
    this.zoomInstance.zoomFactor(Math.max(1, this.mainContext.canvas.height / this.mainImage.height * this.mainContext.imageRatio()), false, true, x);
  }

  redraw(extraBorder) {
    this.mainContext.clear()
    // this.mainContext.setBackgroundColor()
    this.mainContext.drawImageOnCanvas(this.mainImage, 
      { horizontalOffsetCanvas: this.mainContext.canvasHorizontalMargin, verticalOffsetCanvas: this.mainContext.canvasVerticalMargin, horizontalWidthCanvas: this.mainContext.imageCanvasWidth(), verticalHeightCanvas: this.mainContext.imageCanvasHeight() }
    );
    this.mainContext.initBlur(0)

    this.mainContext.save()
    this.mainContext.clip()
    this.mainContext.drawImageOnCanvas(this.mainImage, 
      { horizontalOffsetCanvas: this.mainContext.canvasHorizontalMargin, verticalOffsetCanvas: this.mainContext.canvasVerticalMargin, horizontalWidthCanvas: this.mainContext.imageCanvasWidth(), verticalHeightCanvas: this.mainContext.imageCanvasHeight() }
    );
    this.mainContext.restore()

    this.mainContext.drawPolygons({ showEntriesNumbering: false });
  }

  handleZoom(e) {
    if (e.altKey || e.ctrlKey) {
      e.preventDefault();
      
      const delta = e.wheelDelta ? e.wheelDelta / 200 : e.detail ? -e.detail : 0
      if (delta)
        this.zoomInstance.zoom(delta, e.ctrlKey)
      return false
    } else {
      e.preventDefault();
      this.canvasScrollbar.handleMove(e);
    }
  }
}
